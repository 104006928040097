import 'animate.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import App from './App';
import './assets/custom.scss';
import './assets/style.css';
import { productsByCategory } from './data/products';
import { About, Contact, ErrorPage, Home, Product, Projects, SAV, SimulatorLayout } from './pages';

const routes = [
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: 'qui-sommes-nous',
                element: <About />
            },
            {
                path: 'nos-produits',
                children: Object.keys(productsByCategory).map(category => ({
                    path: productsByCategory[category].path,
                    element: <Product category={category} />
                }))
            },
            {
                path: 'nos-produits/pompes-a-chaleur',
                element: <Product category='heatpumps' />
            },
            {
                path: 'installations-realisees',
                element: <Projects />
            },
            {
                path: 'contactez-nous',
                element: <Contact />
            },
            {
                path: 'sav',
                element: <SAV />
            },
            {
                path: 'mentions-legales',
                element: <div>Mentions légales</div>
            },
            {
                path: 'politique-de-confidentialite',
                element: <div>Politique de confidentialité</div>
            },
            {
                path: 'politique-de-cookies',
                element: <div>Politique de cookies</div>
            }
        ]
    },
    {
        path: 'simulateur',
        element: <SimulatorLayout />,
        errorElement: <ErrorPage />
    }
];

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={createBrowserRouter(routes)} />
    </React.StrictMode>
);
