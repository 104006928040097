import { Col, Container, Image, Row } from 'react-bootstrap';
import About1 from './../assets/images/qui-sommes-nous-1.jpg';
// import About2 from './../assets/images/qui-sommes-nous-2.jpg';

const About = () => {
    return (
        <>
            <div className='container-fluid page-header-about py-5'>
                <div className='container py-5'>
                    <h1 className='display-3 text-white mb-3 animated slideInDown'>Qui sommes-nous ?</h1>
                </div>
            </div>

            <Container fluid className='py-5'>
                <Container className='py-5'>
                    <Row>
                        <Col md={12}>
                            <div className='text-center mb-5'>
                                <h6 className='text-primary'>À PROPOS</h6>
                                <h2>Schaffner Energy Group SA</h2>
                            </div>
                            <p>
                                Schaffner Energy Group SA est une entreprise présente dans toute la Suisse romande et
                                spécialisée dans les projets énergétiques durables pour les particuliers et les
                                professionnels. Notre équipe d'experts solaires chevronnés est engagée dans la
                                fourniture de solutions énergétiques innovantes et respectueuses de l'environnement.
                                Nous représentons des marques internationales de renom dans le secteur des énergies
                                renouvelables, garantissant ainsi la qualité de nos produits. Notre force réside dans
                                notre équipe passionnée, déterminée à fournir les meilleures solutions énergétiques
                                possibles. Que vous soyez un particulier cherchant à réduire votre empreinte carbone ou
                                une entreprise désireuse de passer à une énergie plus propre, nous nous efforçons de
                                rendre l'énergie solaire accessible à tous.
                            </p>
                            <p>
                                Avec Schaffner Energy Group SA, vous avez un partenaire solide pour un avenir
                                énergétique plus propre.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className='bg-light px-lg-0'>
                <Container className='feature px-lg-0'>
                    <Row className='g-0 mx-lg-0'>
                        <Col md={6} style={{ minHeight: '400px' }}>
                            <div className='position-relative h-100'>
                                <Image
                                    fluid
                                    className='position-absolute w-100 h-100'
                                    src={About1}
                                    style={{ objectFit: 'cover' }}
                                    alt=''
                                />
                            </div>
                        </Col>
                        <Col md={6} className='py-5 p-md-5'>
                            <div className='p-md-5'>
                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <h6 className='text-primary'>POURQUOI NOUS CHOISIR</h6>
                                    <h2 className='mb-4'>Expert en énergie solaire</h2>
                                </div>
                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        Schaffner Energy Group SA se distingue par une expertise solide, un engagement
                                        continu envers l'innovation, des partenariats internationaux de confiance, et un
                                        dévouement absolu à l'environnement. Découvrez pourquoi nous sommes le choix
                                        idéal pour vos projets énergétiques durables.
                                    </p>
                                </div>
                                <br />
                                <Row className='g-4'>
                                    <Col lg={6} className='wow animate__fadeIn' data-wow-duration='1s'>
                                        <div className='d-flex align-items-center'>
                                            <div className='btn-lg-square bg-primary rounded-circle'>
                                                <i className='fa fa-check text-white'></i>
                                            </div>
                                            <div className='ms-4'>
                                                <h5 className='mb-0'>Expertise Solide</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='wow animate__fadeIn' data-wow-duration='1.5s'>
                                        <div className='d-flex align-items-center'>
                                            <div className='btn-lg-square bg-primary rounded-circle'>
                                                <i className='fa fa-check text-white'></i>
                                            </div>
                                            <div className='ms-4'>
                                                <h5 className='mb-0'>Innovation Permanente</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='wow animate__fadeIn' data-wow-duration='1s'>
                                        <div className='d-flex align-items-center'>
                                            <div className='btn-lg-square bg-primary rounded-circle'>
                                                <i className='fa fa-check text-white'></i>
                                            </div>
                                            <div className='ms-4'>
                                                <h5 className='mb-0'>Partenariats Internationaux</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='wow animate__fadeIn' data-wow-duration='1.5s'>
                                        <div className='d-flex align-items-center'>
                                            <div className='btn-lg-square bg-primary rounded-circle'>
                                                <i className='fa fa-check text-white'></i>
                                            </div>
                                            <div className='ms-4'>
                                                <h5 className='mb-0'>Engagement Écologique</h5>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* <p>
                    <i className='fa fa-check-circle text-primary me-3'></i>Onduleurs : enphase,
                    APsystemes, SMA, HUAWEI
                </p>
                <p>
                    <i className='fa fa-check-circle text-primary me-3'></i>Panneaux solaires : DUALSUN,
                    VERTEX, SCHAFFNER ENERGY, TRINA SOLAR
                </p>
                <p>
                    <i className='fa fa-check-circle text-primary me-3'></i>Fixations : K2systems, ESDEC
                </p>
                           
            <div className='container-xxl py-5 mb-5'>
                <div className='container'>
                    <div
                        className='text-center mx-auto mb-5 wow fadeInUp'
                        data-wow-delay='0.1s'
                        style={{ maxWidth: '600px' }}
                    >
                        <h6 className='text-primary'>Témoignages</h6>
                        <h1 className='mb-4'>Nos clients parlent de nous</h1>
                    </div>

                    <div className='owl-carousel testimonial-carousel wow fadeInUp' data-wow-delay='0.1s'>
                        <div className='testimonial-item text-center'>
                            <div className='testimonial-img position-relative'>
                                <img
                                    className='img-fluid rounded-circle mx-auto mb-5'
                                    src='img/testimonial/dupont.jpg'
                                />
                                <div className='btn-square bg-primary rounded-circle'>
                                    <i className='fa fa-quote-left text-white'></i>
                                </div>
                            </div>
                            <div className='testimonial-text text-center rounded p-4'>
                                <p>
                                    Depuis que nous avons installé des panneaux solaires chez nous, nous avons réalisé
                                    de vraies économies sur notre facture d'électricité. Nous sommes ravis de produire
                                    notre propre énergie de manière durable et écologique.
                                </p>
                                <h5 className='mb-1'>M. & Mme Marques</h5>
                                <span className='fst-italic'>Propriétaire d'une maison individuelle</span>
                            </div>
                        </div>

                        <div className='testimonial-item text-center'>
                            <div className='testimonial-img position-relative'>
                                <img
                                    className='img-fluid rounded-circle mx-auto mb-5'
                                    src='img/testimonial/martin.jpg'
                                />
                                <div className='btn-square bg-primary rounded-circle'>
                                    <i className='fa fa-quote-left text-white'></i>
                                </div>
                            </div>
                            <div className='testimonial-text text-center rounded p-4'>
                                <p>
                                    Nous avons décidé d'installer des panneaux solaires dans notre entreprise pour
                                    réduire notre empreinte carbone et faire des économies sur notre facture
                                    énergétique. Nous sommes très satisfaits du résultat, et nous encourageons d'autres
                                    entreprises à en faire de même.
                                </p>
                                <h5 className='mb-1'>M. Martin</h5>
                                <span className='fst-italic'>Gérant d'une PME</span>
                            </div>
                        </div>

                        <div className='testimonial-item text-center'>
                            <div className='testimonial-img position-relative'>
                                <img
                                    className='img-fluid rounded-circle mx-auto mb-5'
                                    src='img/testimonial/lefebvre.jpg'
                                />
                                <div className='btn-square bg-primary rounded-circle'>
                                    <i className='fa fa-quote-left text-white'></i>
                                </div>
                            </div>
                            <div className='testimonial-text text-center rounded p-4'>
                                <p>
                                    J'ai choisi d'installer des panneaux solaires sur mon toit pour protéger
                                    l'environnement et éviter de dépendre des énergies polluantes. Je suis très contente
                                    du résultat, et je recommande vivement cette solution à tous ceux qui souhaitent
                                    produire de l'électricité de manière durable.
                                </p>
                                <h5 className='mb-1'>Mme Lefebvre</h5>
                                <span className='fst-italic'>Propriétaire d'une maison individuelle</span>
                            </div>
                        </div>

                        <div className='testimonial-item text-center'>
                            <div className='testimonial-img position-relative'>
                                <img
                                    className='img-fluid rounded-circle mx-auto mb-5'
                                    src='img/testimonial/schmidt.jpg'
                                />
                                <div className='btn-square bg-primary rounded-circle'>
                                    <i className='fa fa-quote-left text-white'></i>
                                </div>
                            </div>
                            <div className='testimonial-text text-center rounded p-4'>
                                <p>
                                    Nous avons opté pour des panneaux solaires pour alimenter notre ferme en
                                    électricité. Nous sommes ravis du résultat, et nous sommes fiers de contribuer à la
                                    transition énergétique de notre région.
                                </p>
                                <h5 className='mb-1'>M. Schmidt</h5>
                                <span className='fst-italic'>Propriétaire d'une ferme</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default About;
