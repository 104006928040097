import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { CardOption } from '../../components';
import { StepProps } from './types';

const options = [
    {
        title: 'Maison',
        icon: 'home',
        value: 'Maison'
    },
    {
        title: 'Bâtiment Industriel',
        icon: 'industry',
        value: 'Bâtiment Industriel'
    },
    {
        title: 'Bâtiment Agricole',
        icon: 'tree',
        value: 'Bâtiment Agricole'
    },
    {
        title: 'Autre',
        icon: 'question-circle',
        value: 'Autre'
    }
];

const Step2 = ({ map, formState, handleValueChange }: StepProps) => {
    useEffect(() => {
        if (!map.current) return;

        map.current.dragPan.disable();
    }, []);

    return (
        <Form className='d-flex flex-column justify-content-center align-content-center mx-5 h-100'>
            <h2 className='mb-4'>Informations supplémentaires</h2>
            <p>
                Avant de continuer, nous avons besoin de quelques informations supplémentaires pour personnaliser
                davantage votre simulation de panneau solaire. Veuillez répondre aux questions suivantes :
            </p>
            <Form.Group controlId='buildingType'>
                <Form.Label>
                    <strong>Sélectionnez le type de bâtiment :</strong>
                </Form.Label>
                <div className='d-flex flex-wrap gap-4'>
                    {options.map((option, index) => (
                        <CardOption
                            key={index}
                            title={option.title}
                            icon={option.icon}
                            selected={formState.buildingType === option.value}
                            onClick={() => handleValueChange('buildingType', option.value)}
                        />
                    ))}
                </div>
            </Form.Group>
        </Form>
    );
};

export default Step2;
