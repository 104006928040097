import { Col, Container, Row } from 'react-bootstrap';
import { productsByCategory } from '../data/products';

import PdfIcon from './../assets/images/pdf-icon.png';

const Product = ({ category }: { category: string }) => {
    const productsByBrand = productsByCategory[category].productsByBrand;
    return (
        <>
            <div
                className={`container-fluid page-header-${
                    productsByCategory[category].bannerClass ? productsByCategory[category].bannerClass : 'fixation'
                } py-5`}
            >
                <div className='container py-5'>
                    <h1 className='display-3 text-white mb-3 animated slideInDown'>
                        {productsByCategory[category].title}
                    </h1>
                </div>
            </div>

            <Container fluid className='bg-light overflow-hidden px-lg-0'>
                <Container className='about px-lg-0'>
                    {Object.keys(productsByBrand).map((brand, index) => (
                        <Row key={index} className='g-0 mx-lg-0'>
                            <Col
                                lg={6}
                                className={`py-5 p-md-5 wow animate__fadeIn ${index % 2 ? 'order-md-2' : 'order-md-1'}`}
                                data-wow-duration='1s'
                            >
                                <div className='MapboxDraw'>
                                    <h6 className='text-primary'>{brand}</h6>
                                    <div className='d-flex align-items-center flex-wrap mb-4'>
                                        <img src={productsByBrand[brand].logo} alt={`Logo ${brand}`} className='pe-3' />
                                        <h2>
                                            <strong>{productsByBrand[brand].title}</strong>
                                        </h2>
                                    </div>
                                    <p>
                                        <strong>{productsByBrand[brand].description}</strong>
                                    </p>
                                    {productsByBrand[brand].products.map((product, productIndex) => (
                                        <div key={productIndex}>
                                            <hr className='my-4' />
                                            <h4>
                                                <i className='fa fa-check-circle text-primary me-3'></i>
                                                {product.title}
                                            </h4>
                                            <p>{product.description}</p>
                                            {product.technicalSheet && (
                                                <p>
                                                    <a href={product.technicalSheet} target='_blank' rel='noreferrer'>
                                                        <img src={PdfIcon} alt='' />
                                                    </a>
                                                    <a
                                                        href={product.technicalSheet}
                                                        title='pdf'
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        Fiche technique {product.title}
                                                    </a>
                                                </p>
                                            )}
                                            {product.specs && (
                                                <ul>
                                                    {product.specs.map((spec, specIndex) => (
                                                        <li key={specIndex}>{spec}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Col>

                            <Col
                                lg={6}
                                className={`ps-lg-0 wow animate__fadeIn ${index % 2 ? 'order-md-1' : 'order-md-2'}`}
                                data-wow-duration='1s'
                                style={{ minHeight: '400px' }}
                            >
                                <div className='position-relative h-100'>
                                    <img
                                        className='position-absolute img-fluid w-100 h-100'
                                        src={productsByBrand[brand].image}
                                        style={{ objectFit: 'cover' }}
                                        alt={brand}
                                    />
                                </div>
                            </Col>
                        </Row>
                    ))}
                </Container>
            </Container>
        </>
    );
};

export default Product;
