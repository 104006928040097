/**
 * @type {UserConfig}
 */
const pluginConfig = {
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false
    gui_options: {
        consent_modal: {
            layout: 'cloud', // box/cloud/bar
            position: 'bottom right', // bottom/middle/top + left/right/center
            transition: 'zoom', // zoom/slide
            swap_buttons: false // enable to invert buttons
        }
    },

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    // onFirstAction: function (user_preferences, cookie) {
    //     // callback triggered only once
    //     const analyticsEnabled = window.CC.allowedCategory('analytics');
    //     console.log(`analytics ${analyticsEnabled ? 'enabled' : 'disabled'}`);
    // },

    // onAccept: function (cookie) {
    //     // ...
    // },

    // onChange: function (cookie, changed_preferences) {
    //     // ...
    // },

    languages: {
        en: {
            consent_modal: {
                title: 'Gérer le consentement aux cookies',
                description:
                    'Pour offrir les meilleures expériences, nous utilisons des technologies telles que les cookies pour stocker et/ou accéder aux informations des appareils. Le fait de consentir à ces technologies nous permettra de traiter des données telles que le comportement de navigation ou les ID uniques sur ce site. Le fait de ne pas consentir ou de retirer son consentement peut avoir un effet négatif sur certaines caractéristiques et fonctions. <button type="button" data-cc="c-settings" class="cc-link">Laissez-moi choisir</button>',
                primary_btn: {
                    text: 'Accepter tout',
                    role: 'accept_all' // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Voir les préférences',
                    role: 'settings' // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Paramètres des cookies',
                save_settings_btn: 'Sauvegarder les paramètres',
                accept_all_btn: 'Accepter tout',
                reject_all_btn: 'Refuser tout',
                close_btn_label: 'Fermer',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Domain' },
                    { col3: 'Expiration' },
                    { col4: 'Description' }
                ],
                blocks: [
                    {
                        title: 'Utilisation des cookies 📢',
                        description:
                            'Nous utilisons des cookies pour assurer les fonctionnalités de base du site web et pour améliorer votre expérience en ligne. Pour chaque catégorie, vous pouvez choisir d\'accepter ou de refuser les cookies quand vous le souhaitez. Pour plus de détails concernant les cookies et autres données sensibles, veuillez lire la politique de confidentialité complète. <a href="/politique-de-confidentialite" class="cc-link">Politique de confidentialité</a>.'
                    },
                    {
                        title: 'Cookies nécessaires',
                        description:
                            'Ces cookies sont essentiels au bon fonctionnement du site web. Sans ces cookies, le site ne fonctionnerait pas correctement',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },
                    {
                        title: "Cookies de performance et d'analyse",
                        description:
                            'Ces cookies permettent au site web de se souvenir des choix que vous avez faits dans le passé ou sont utilisés exclusivement à des fins statistiques',
                        toggle: {
                            value: 'analytics', // your cookie category
                            enabled: false,
                            readonly: false
                        }
                        // cookie_table: [
                        //     // list of all expected cookies
                        //     {
                        //         col1: '^_ga', // match all cookies starting with "_ga"
                        //         col2: 'google.com',
                        //         col3: '2 years',
                        //         col4: 'description ...',
                        //         is_regex: true
                        //     },
                        //     {
                        //         col1: '_gid',
                        //         col2: 'google.com',
                        //         col3: '1 day',
                        //         col4: 'description ...'
                        //     }
                        // ]
                    },
                    {
                        title: 'Cookies publicitaires et de ciblage',
                        description:
                            'Ces cookies recueillent des informations sur la façon dont vous utilisez le site web, les pages que vous avez visitées et les liens sur lesquels vous avez cliqué. Toutes les données sont anonymes et ne peuvent être utilisées pour vous identifier',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    },
                    {
                        title: "Plus d'informations",
                        description:
                            'Pour toute question relative à notre politique en matière de cookies et à vos choix, <a class="cc-link" href="/contactez-nous">contactez-nous</a>.'
                    }
                ]
            }
        }
    }
};

export default pluginConfig;
