import { useEffect, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { productsByCategory } from '../data/products';

const Footer = () => {
    const [backToTop, setBackToTop] = useState(Boolean);

    useEffect(() => {
        window.addEventListener('scroll', showBackToTopButton);
        return () => window.removeEventListener('scroll', showBackToTopButton);
    }, []);

    const showBackToTopButton = () => {
        if (window !== undefined) setBackToTop(window.scrollY > 150);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <Container fluid className={`bg-dark text-body footer pt-5`}>
                <Container className='py-5'>
                    <Row className='g-5'>
                        <div className='col-lg-4 col-md-6 footer-address'>
                            <h5 className='text-white mb-4'>Adresse</h5>
                            <p className='mb-2'>
                                <Button
                                    variant='link'
                                    href=''
                                    // href='https://maps.google.com/?q=Route+de+la+Jorette+1,+Torgon,+Suisse'
                                    target='_blank'
                                    className='p-0 text-start'
                                >
                                    <i className='fa fa-map-marker-alt me-3'></i>Route de la Jorette 1, 1899 Torgon,{' '}
                                    <br />
                                    Canton du Valais
                                </Button>
                            </p>
                            <p className='mb-2'>
                                <Button
                                    variant='link'
                                    href=''
                                    // href='https://maps.google.com/?q=Route+de+Gouillon+14,+Saint-Gingolph,+Suisse'
                                    target='_blank'
                                    className='p-0 text-start'
                                >
                                    <i className='fa fa-map-marker-alt me-3'></i>Route de Gouillon 14, 1898 Saint
                                    Gingolph, <br />
                                    Canton du Valais
                                </Button>
                            </p>
                            <p className='mb-2'>
                                <Button variant='link' href='tel:+410275602112' className='p-0'>
                                    <i className='fa fa-phone-alt me-3'></i>+41 027 560 21 12
                                </Button>
                            </p>
                            <p className='mb-2'>
                                <Button
                                    variant='link'
                                    href='mailto:contact@schaffner-energy.ch'
                                    className='p-0 text-lowercase'
                                >
                                    <i className='fa fa-envelope me-3'></i>contact@schaffner-energy.ch
                                </Button>
                            </p>
                            <div className='d-flex pt-2'>
                                <a
                                    className='btn btn-square btn-outline-light btn-social'
                                    href='https://www.instagram.com/schaffnerenergy/'
                                    target='_blank'
                                >
                                    <i className='fab fa-instagram'></i>
                                </a>
                                <a
                                    className='btn btn-square btn-outline-light btn-social'
                                    href='https://www.linkedin.com/company/schaffner-energy/'
                                    target='_blank'
                                >
                                    <i className='fab fa-linkedin-in'></i>
                                </a>
                                <a
                                    className='btn btn-square btn-outline-light btn-social'
                                    href='https://www.facebook.com/people/Schaffner-Energy/100095402058704/'
                                    target='_blank'
                                >
                                    <i className='fab fa-facebook-f'></i>
                                </a>
                            </div>
                        </div>

                        <div className='col-lg-8 footer-nav'>
                            <Row className='g-5'>
                                <div className='col-lg-4 col-md-6'>
                                    <h5 className='text-white mb-4'>Nos Produits</h5>
                                    {Object.keys(productsByCategory).map((category, index) => {
                                        if (productsByCategory[category].productPage === false) return null;
                                        return (
                                            <NavLink
                                                key={index}
                                                className='btn btn-link'
                                                to={`/nos-produits/${productsByCategory[category].path}`}
                                            >
                                                {productsByCategory[category].title}
                                            </NavLink>
                                        );
                                    })}
                                </div>

                                <div className='col-lg-4 col-md-6'>
                                    <h5 className='text-white mb-4'>Plan du site</h5>
                                    <NavLink className='btn btn-link' to='/'>
                                        Accueil
                                    </NavLink>
                                    <NavLink className='btn btn-link' to='/qui-sommes-nous'>
                                        Qui sommes-nous ?
                                    </NavLink>
                                    <NavLink className='btn btn-link' to='/installations-realisees'>
                                        Installations réalisées
                                    </NavLink>
                                    <NavLink className='btn btn-link' to='/contactez-nous'>
                                        Contactez-nous
                                    </NavLink>
                                    <NavLink className='btn btn-link' to='/sav'>
                                        Service Après Vente
                                    </NavLink>
                                    <NavLink className='btn btn-link' to='/simulateur'>
                                        Simuler mon projet
                                    </NavLink>
                                </div>

                                <div className='col-lg-4 col-md-6'>
                                    <h5 className='text-white mb-4'>Légales</h5>
                                    <NavLink className='btn btn-link' to='/mentions-legales'>
                                        Mentions légales
                                    </NavLink>
                                    <NavLink className='btn btn-link' to='/politique-de-confidentialite'>
                                        Politique de confidentialité
                                    </NavLink>
                                    <NavLink className='btn btn-link' to='/politique-de-cookies'>
                                        Politique de cookies
                                    </NavLink>
                                </div>
                            </Row>
                        </div>
                    </Row>
                </Container>

                <div className='container'>
                    <div className='copyright'>
                        <div className='row'>
                            <div className='col-md-6 text-center text-md-start mb-3 mb-md-0'>
                                &copy; <NavLink to='/'>Schaffner Energy Group SA</NavLink>, Tous droits réservés.
                            </div>
                            <div className='col-md-6 text-center text-md-end'>
                                Site développé par{' '}
                                <a
                                    href='https://allbecom.fr/?utm_source=schaffner-energy&utm_medium=footer&utm_campaign=made_by'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    ALL BECOM
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <button
                onClick={scrollToTop}
                className={`btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top ${
                    !backToTop ? 'hide' : ''
                }`}
            >
                <i className='bi bi-arrow-up'></i>
            </button>
        </>
    );
};

export default Footer;
