import { useEffect } from 'react';
import pluginConfig from './CookieConsentConfig';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

declare global {
    interface Window {
        CC: any;
    }
}

const CookieConsent = () => {
    useEffect(() => {
        if (!document.getElementById('cc--main')) {
            window.CC = window.initCookieConsent();
            window.CC.run(pluginConfig);
        }
    }, []);

    return null;
};

export default CookieConsent;
