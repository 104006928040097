import { useState } from 'react';
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Partners, ProjectCard, Steps } from '../components';
import CantonsSuisse from './../assets/images/26-cantons-suisse.jpg';

import { productsByCategory } from '../data/products';
import { Project, projects } from '../data/projects';
import ProjetsEnergetiques2 from './../assets/images/projets-energetiques-2.jpg';
import ProjetsEnergetiques from './../assets/images/projets-energetiques.jpg';
import Carousel1 from './../assets/images/slider/carousel-1.jpg';
import Carousel2 from './../assets/images/slider/carousel-2.jpg';
import CarouselLoiClimat from './../assets/images/slider/carousel-loi-climat.jpg';
import { PhotoModal } from './Projects';

const slides = [
    {
        title: 'Oui à la loi climat !',
        subtitle: 'Ensemble, agissons pour préserver nos montagnes, notre climat, notre avenir',
        image: CarouselLoiClimat,
        subtitleColor: 'text-white',
        button: {
            label: 'En savoir plus',
            url: 'https://oui-loi-climat.ch/',
            nofollow: true
        }
    },
    {
        title: 'Maitrisez votre facture et réduisez votre impact',
        subtitle: 'pour un avenir énergétique plus responsable',
        image: Carousel2,
        subtitleColor: 'text-white',
        button: {
            label: 'Simuler mon projet',
            url: 'simulateur'
        }
    },
    {
        title: 'Anticiper la transition énergétique',
        subtitle: 'et préparez-vous à un avenir durable avec SCHAFFNER ENERGY GROUP SA',
        image: Carousel1,
        button: {
            label: 'Qui sommes-nous ?',
            url: 'qui-sommes-nous'
        }
    }
];

const HomeSlider = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: 'vertical-dots',
        customPaging: function (i: number) {
            return (
                <a>
                    <img src={slides[i].image} alt='' />
                </a>
            );
        }
    };
    return (
        <div className='container-fluid p-0 wow fadeIn top' data-wow-delay='0.1s'>
            <Slider {...settings} className='header-carousel'>
                {slides.map((slide, index) => (
                    <div key={index} className='position-relative'>
                        <img className='img-fluid' src={slide.image} alt='' />
                        <div className='carousel-inner'>
                            <div className='container'>
                                <div className='row justify-content-start'>
                                    <div className='col-10 col-lg-8'>
                                        <h1 className='display-2 animate__animated animate__slideInDown text-primary shadow-title'>
                                            {slide.title}
                                        </h1>
                                        <p
                                            className={`fs-5 fw-medium mb-4 pb-3 ${
                                                slide.subtitleColor || 'text-secondary'
                                            }`}
                                        >
                                            {slide.subtitle}
                                        </p>
                                        {slide.button && (
                                            <NavLink
                                                to={slide.button.url}
                                                className='btn btn-primary rounded-pill py-3 px-5 animate__animated animate__slideInLeft'
                                                target={slide.button.nofollow ? '_blank' : ''}
                                                rel={slide.button.nofollow ? 'noopener noreferrer nofollow' : ''}
                                            >
                                                {slide.button.label}
                                            </NavLink>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

const CardProduit = ({
    icon,
    label,
    index = 1,
    url,
    noLink = false
}: {
    icon: string;
    label: string;
    index?: number;
    url: string;
    noLink: boolean;
}) => (
    <Card
        className='product-card shadow border-0 p-2 wow animate__fadeInUp'
        data-wow-offset='100'
        data-wow-duration={`${1 + index * 0.2}s`}
        as={NavLink}
        to={noLink ? '' : `/nos-produits/${url}`}
    >
        <Card.Body className='d-flex flex-column text-center lh-sm'>
            <i className={`fa fa-${icon}`}></i>
            <span className='mt-3'>{label}</span>
        </Card.Body>
    </Card>
);

const Home = () => {
    const [showProject, setShowProject] = useState(false);
    const [currentProject, setCurrentProject] = useState<Project | null>(null);

    const handleShow = (project: Project) => {
        setCurrentProject(project);
        setShowProject(true);
    };

    return (
        <>
            <HomeSlider />

            {/* Product Categories */}
            <Container className='text-center mb-5'>
                <div className='d-flex justify-content-center flex-wrap gap-4 mt-5 mt-md-n10'>
                    {Object.keys(productsByCategory).map((category, index) => {
                        if (productsByCategory[category].showInHero === false) return null;
                        return (
                            <CardProduit
                                key={index}
                                index={index}
                                icon={productsByCategory[category].icon}
                                label={productsByCategory[category].title}
                                url={productsByCategory[category].path}
                                noLink={productsByCategory[category].productPage === false}
                            />
                        );
                    })}
                </div>
            </Container>

            {/* Simulator */}
            <Container fluid style={{ marginTop: '8rem', marginBottom: '8rem' }}>
                <Container className='text-center text-md-start'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 wow animate__fadeInLeft' data-wow-duration='1s'>
                            <h6 className='text-primary'>SIMULATEUR</h6>
                            <h2 className='mb-4'>Estimation personnalisée de votre projet solaire</h2>
                            <p className='text-start'>
                                Explorez les avantages de l'énergie solaire pour votre propriété avec notre simulateur.
                                Découvrez comment une installation de panneaux solaires peut répondre à vos besoins
                                énergétiques et générer des économies. Commencez votre transition vers une énergie
                                propre en utilisant notre outil dès maintenant.
                            </p>
                            <NavLink to='/simulateur' className='btn btn-primary'>
                                Simuler mon projet<i className='fa fa-arrow-right ms-3'></i>
                            </NavLink>
                        </div>
                        <div
                            className='col-lg-6 mt-4 mt-md-0 text-center wow animate__fadeInRight'
                            data-wow-duration='1s'
                        >
                            <img
                                src='https://simulateur-solaire.org/assets/img/image-simulateur-solaire.png'
                                style={{ width: '80%' }}
                                alt='Simulateur Solaire'
                            />
                        </div>
                    </div>
                </Container>
            </Container>

            {/* About */}
            <Container fluid className='bg-light px-lg-0'>
                <Container className='about px-lg-0'>
                    <Row className='g-0 mx-lg-0'>
                        <Col md={6} style={{ minHeight: '400px' }}>
                            <div className='position-relative h-100'>
                                <img
                                    className='position-absolute img-fluid w-100 h-100'
                                    src={ProjetsEnergetiques}
                                    style={{ objectFit: 'cover' }}
                                    alt=''
                                />
                            </div>
                        </Col>
                        <Col md={6} className='py-5 p-md-5'>
                            <div className='p-md-5'>
                                <div className='text-center text-md-start wow animate__fadeIn' data-wow-duration='1s'>
                                    <h6 className='text-primary'>
                                        SCHAFFNER ENERGY GROUP SA, VOTRE EXPERT EN ÉNERGIE SOLAIRE
                                    </h6>
                                    <h2 className='mb-4'>Notre engagement pour vos projets énergétiques</h2>
                                </div>

                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        <i className='fa fa-check-circle text-primary me-3'></i>
                                        <strong>Partenaire de confiance dans toute la Suisse romande</strong>
                                    </p>
                                    <p>
                                        Schaffner Energy Group SA est votre partenaire de confiance pour la réalisation
                                        de projets énergétiques solaires, mettant son expertise à votre service avec un
                                        service personnalisé.
                                    </p>
                                </div>

                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        <i className='fa fa-check-circle text-primary me-3'></i>
                                        <strong>Produisez votre propre électricité !</strong>
                                    </p>
                                    <p>
                                        Exploitez la puissance du soleil qui fournit bien plus d'énergie que toute
                                        l'humanité ne pourrait en consommer. Produisez votre propre électricité durable
                                        grâce à une installation solaire.
                                    </p>
                                </div>

                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        <i className='fa fa-check-circle text-primary me-3'></i>
                                        <strong>Solutions complètes pour tous</strong>
                                    </p>
                                    <p>
                                        Que vous soyez un particulier cherchant à équiper sa maison ou un professionnel
                                        gérant une usine, une exploitation agricole ou un hangar agricole, Schaffner
                                        Energy Group SA vous propose des solutions complètes pour répondre à vos besoins
                                        énergétiques.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Steps */}
            <Container fluid style={{ marginTop: '8rem', marginBottom: '8rem' }}>
                <Container>
                    <div className='text-center wow animate__fadeInUp' data-wow-duration='0.5s'>
                        <h6 className='text-primary'>LES ÉTAPES DE VOTRE PROJET</h6>
                        <h2 className='mb-4'>Concrétisez votre transition énergétique en 4 étapes</h2>
                    </div>
                    <Steps />
                    <Row className='mt-5'>
                        <NavLink
                            to='/contactez-nous'
                            className='text-center wow animate__fadeInUp'
                            data-wow-duration='1s'
                        >
                            <Button>
                                Contactez-nous<i className='fa fa-arrow-right ms-3'></i>
                            </Button>
                        </NavLink>
                    </Row>
                </Container>
            </Container>

            {/* About */}
            <Container fluid className='bg-light px-lg-0'>
                <Container className='about px-lg-0'>
                    <Row className='g-0 mx-lg-0'>
                        <Col md={6} className='py-5 p-md-5 order-2 order-md-1'>
                            <div className='p-md-5'>
                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <h6 className='text-primary'>VOTRE PARTENAIRE SOLIDE DURANT TOUT LE PROJET</h6>
                                    <h2 className='mb-4'>Gestion complète de votre projet</h2>
                                </div>

                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        Schaffner Energy Group SA est reconnue pour son expertise dans le domaine de
                                        l'énergie solaire. Notre équipe hautement qualifiée est composée d'experts
                                        solaires chevronnés prêts à vous accompagner à chaque étape de votre projet.
                                        Nous avons bâti notre réputation sur la réussite de projets énergétiques
                                        solaires de grande envergure, tant pour les particuliers que pour les
                                        entreprises. Faites confiance à nos spécialistes pour vous guider vers une
                                        énergie propre et durable.
                                    </p>
                                </div>

                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        <i className='fa fa-check-circle text-primary me-3'></i>
                                        <strong>Conseil Personnalisé</strong>
                                    </p>
                                    <p>
                                        Nos experts solaires vous offrent un conseil personnalisé pour optimiser votre
                                        installation solaire, y compris le nombre de panneaux solaires, le type
                                        d'onduleur et les fixations.
                                    </p>
                                </div>

                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        <i className='fa fa-check-circle text-primary me-3'></i>
                                        <strong>Gestion Complète</strong>
                                    </p>
                                    <p>
                                        Nous gérons l'ensemble du processus, des démarches administratives à la
                                        validation de la conformité, pour que vous n'ayez à vous soucier de rien.
                                    </p>
                                </div>

                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        <i className='fa fa-check-circle text-primary me-3'></i>
                                        <strong>Engagement Environnemental</strong>
                                    </p>
                                    <p>
                                        Schaffner Energy Group SA s'engage à promouvoir une énergie propre et durable,
                                        contribuant ainsi à un avenir plus respectueux de l'environnement.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className='order-1 order-md-2' style={{ minHeight: '400px' }}>
                            <div className='position-relative h-100'>
                                <Image
                                    fluid
                                    className='position-absolute w-100 h-100'
                                    src={ProjetsEnergetiques2}
                                    style={{ objectFit: 'cover' }}
                                    alt='Équipe Schaffner Energy Group SA'
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Product Categories */}
            <Container fluid style={{ marginTop: '8rem', marginBottom: '8rem' }}>
                <Container>
                    <div
                        className='text-center mx-auto mb-5 wow animate__fadeInUp'
                        data-wow-duration='1s'
                        style={{ maxWidth: '600px' }}
                    >
                        <h6 className='text-primary'>
                            FIXATIONS • ONDULEURS • PANNEAUX SOLAIRES • CHARGEURS DE VOITURES
                        </h6>
                        <h1 className='mb-4'>Découvrez nos produits pour vos différentes installations :</h1>
                    </div>
                    <div className='row g-4'>
                        {Object.keys(productsByCategory).map((category, index) => (
                            <div
                                key={index}
                                className='col-md-6 col-lg-4 wow animate__fadeInUp'
                                data-wow-duration={`${1 + index * 0.2}s`}
                            >
                                <div className='service-item rounded overflow-hidden'>
                                    <img
                                        className='object-fit-cover w-100'
                                        src={productsByCategory[category].image}
                                        style={{ height: '250px' }}
                                        alt=''
                                    />
                                    <div className='position-relative p-4 pt-0'>
                                        <div className='service-icon'>
                                            <i className={`fa fa-${productsByCategory[category].icon} fa-3x`}></i>
                                        </div>
                                        <h4 className='mb-3'>{productsByCategory[category].title}</h4>
                                        <p>{productsByCategory[category].description}</p>
                                        {productsByCategory[category].productPage !== false ? (
                                            <NavLink
                                                className='small fw-medium'
                                                to={`/nos-produits/${productsByCategory[category].path}`}
                                            >
                                                Découvrir<i className='fa fa-arrow-right ms-2'></i>
                                            </NavLink>
                                        ) : (
                                            <br />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </Container>

            {/* Subventions */}
            <Container fluid className='bg-light px-lg-0'>
                <Container className='feature px-lg-0'>
                    <Row className='g-0 mx-lg-0'>
                        <Col md={6} style={{ minHeight: '400px' }}>
                            <div className='position-relative h-100'>
                                <Image
                                    fluid
                                    className='position-absolute w-100 h-100'
                                    src={CantonsSuisse}
                                    style={{ objectFit: 'cover' }}
                                    alt=''
                                />
                            </div>
                        </Col>
                        <Col md={6} className='py-5 p-md-5'>
                            <div className='p-md-5'>
                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <h6 className='text-primary'>SUBVENTIONS</h6>
                                    <h2 className='mb-4'>Vos subventions octroyées pour l'année 2023 / 2024</h2>
                                </div>
                                <div className='wow animate__fadeIn' data-wow-duration='1s'>
                                    <p>
                                        Bénéficiez des subventions pour la réalisation de vos projets énergétiques dans
                                        votre canton.
                                    </p>
                                    <p>
                                        Notre service est entièrement dédié à la demande et à la mise en œuvre de ces
                                        subventions, que ce soit auprès de votre commune, de votre canton ou de la
                                        confédération.
                                    </p>
                                    <p>
                                        Selon la nature de votre projet, les aides financières pourront être octroyées
                                        par une ou deux des trois administrations mentionnées précédemment.
                                    </p>
                                    <p>
                                        Nous veillons à respecter scrupuleusement les critères de sélection pour
                                        l'obtention de ces primes.
                                    </p>
                                    <p>
                                        Ces dernières peuvent varier chaque année et leurs conditions d'attribution
                                        également, c'est pourquoi il est important de rester informé des évolutions en
                                        la matière.
                                    </p>
                                </div>
                                <br />
                                <Row className='g-4'>
                                    <Col lg={6} className='wow animate__fadeIn' data-wow-duration='1s'>
                                        <div className='d-flex align-items-center'>
                                            <div className='btn-lg-square bg-primary rounded-circle'>
                                                <i className='fa fa-check text-white'></i>
                                            </div>
                                            <div className='ms-4'>
                                                <p className='mb-0'>Subventions</p>
                                                <h5 className='mb-0'>Travaux</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='wow animate__fadeIn' data-wow-duration='1.5s'>
                                        <div className='d-flex align-items-center'>
                                            <div className='btn-lg-square bg-primary rounded-circle'>
                                                <i className='fa fa-check text-white'></i>
                                            </div>
                                            <div className='ms-4'>
                                                <p className='mb-0'>Experts</p>
                                                <h5 className='mb-0'>Service dédié</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='wow animate__fadeIn' data-wow-duration='1s'>
                                        <div className='d-flex align-items-center'>
                                            <div className='btn-lg-square bg-primary rounded-circle'>
                                                <i className='fa fa-check text-white'></i>
                                            </div>
                                            <div className='ms-4'>
                                                <p className='mb-0'>Subventions</p>
                                                <h5 className='mb-0'>Dans toute la Suisse Romande</h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='wow animate__fadeIn' data-wow-duration='1.5s'>
                                        <div className='d-flex align-items-center'>
                                            <div className='btn-lg-square bg-primary rounded-circle'>
                                                <i className='fa fa-check text-white'></i>
                                            </div>
                                            <div className='ms-4'>
                                                <p className='mb-0'>Respect</p>
                                                <h5 className='mb-0'>Cahier des Charges</h5>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Projects */}
            <Container fluid style={{ marginTop: '8rem', marginBottom: '8rem' }}>
                <Container>
                    <div className='text-center wow animate__fadeInUp' data-wow-duration='0.5s'>
                        <h6 className='text-primary'>INSTALLATIONS RÉALISÉES</h6>
                        <h2 className='mb-4'>Découvrez nos réalisations</h2>
                    </div>
                    <Row className='mb-4 home-projects'>
                        {projects.slice(0, 4).map((project: Project, index) => (
                            <ProjectCard
                                key={index}
                                img={project.img}
                                category={project.category}
                                subtitle={project.subtitle}
                                openModal={() => handleShow(project)}
                                column='col-lg-3 col-md-6'
                                animation='fadeInUp'
                                animationDuration={`${1 + index * 0.2}s`}
                            />
                        ))}
                        {currentProject && (
                            <PhotoModal
                                show={showProject}
                                project={currentProject}
                                onHide={() => setShowProject(false)}
                            />
                        )}
                    </Row>
                    <Row>
                        <NavLink
                            to='/installations-realisees'
                            className='text-center wow animate__fadeInUp'
                            data-wow-duration='1s'
                        >
                            <Button>
                                Voir plus de réalisations<i className='fa fa-arrow-right ms-3'></i>
                            </Button>
                        </NavLink>
                    </Row>
                </Container>
            </Container>

            {/* Partners */}
            <Container fluid style={{ marginTop: '8rem', marginBottom: '8rem' }}>
                <Container>
                    <div
                        className='text-center mx-auto mb-5 wow animate__fadeInUp'
                        data-wow-duration='1s'
                        style={{ maxWidth: '600px' }}
                    >
                        <h1 className='mb-4'>Nos partenaires de confiance</h1>
                    </div>

                    <div className='testimonial-carousel wow animate__fadeInUp' data-wow-duration='1s'>
                        <Partners />
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default Home;
