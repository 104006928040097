import { useEffect, useState } from 'react';
import { Nav, NavDropdown, Navbar as RbNavbar } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo-schaffner-energy-group-sa.png';
import { productsByCategory } from '../data/products';

const Navbar = () => {
    const [stickyClass, setStickyClass] = useState('');
    const location = useLocation();

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 150 ? setStickyClass('shadow-sm active') : setStickyClass('');
        }
    };

    return (
        <RbNavbar collapseOnSelect={true} expand='lg' bg='white' className={`sticky-top p-0 ${stickyClass}`}>
            <RbNavbar.Brand as={NavLink} to='/' className='d-flex align-items-center border-end px-4 px-lg-5'>
                <img src={logo} height='70px' width='218px' alt='logo schaffner energy group sa' />
            </RbNavbar.Brand>
            <RbNavbar.Toggle label='navbarCollapse' className='me-4' />
            <RbNavbar.Collapse id='navbarCollapse'>
                <Nav activeKey={location.pathname} className='ms-auto p-4 p-lg-0'>
                    <Nav.Link
                        eventKey={'qui-sommes-nous'}
                        as={NavLink}
                        to='/qui-sommes-nous'
                        className='nav-item nav-link'
                    >
                        Qui sommes-nous
                    </Nav.Link>

                    <NavDropdown
                        title='Nos Produits'
                        id='basic-nav-dropdown'
                        active={location.pathname.includes('nos-produits')}
                    >
                        {Object.keys(productsByCategory).map((category, index) => {
                            if (productsByCategory[category].productPage === false) return null;
                            return (
                                <NavDropdown.Item
                                    eventKey={productsByCategory[category].path}
                                    key={index}
                                    as={NavLink}
                                    to={`/nos-produits/${productsByCategory[category].path}`}
                                    active={location.pathname.includes(productsByCategory[category].path)}
                                >
                                    {productsByCategory[category].title}
                                </NavDropdown.Item>
                            );
                        })}
                    </NavDropdown>

                    <Nav.Link
                        eventKey={'installations-realisees'}
                        as={NavLink}
                        to='/installations-realisees'
                        className='nav-item nav-link'
                    >
                        Installations réalisées
                    </Nav.Link>

                    <Nav.Link
                        eventKey={'contactez-nous'}
                        as={NavLink}
                        to='/contactez-nous'
                        className='nav-item nav-link'
                    >
                        Contactez-nous
                    </Nav.Link>

                    <Nav.Link eventKey={'sav'} as={NavLink} to='/sav' className='nav-item nav-link'>
                        SAV
                    </Nav.Link>
                    <NavLink to='/simulateur' className='btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block'>
                        Simuler mon projet<i className='fa fa-arrow-right ms-3'></i>
                    </NavLink>
                    <NavLink to='/simulateur' className='btn btn-primary btn-collapse d-lg-none'>
                        Simuler mon projet<i className='fa fa-arrow-right ms-3'></i>
                    </NavLink>
                </Nav>
            </RbNavbar.Collapse>
        </RbNavbar>
    );
};

export default Navbar;
