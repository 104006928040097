export const switzerlandBoundary = {
    type: 'FeatureCollection',
    features: [
        {
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [10.453811, 46.864427],
                        [10.44854, 46.832233],
                        [10.444923, 46.823241],
                        [10.439032, 46.816885],
                        [10.417224, 46.79885],
                        [10.419085, 46.783967],
                        [10.426216, 46.76942],
                        [10.428696, 46.755648],
                        [10.416604, 46.743014],
                        [10.399654, 46.735546],
                        [10.395623, 46.7264],
                        [10.396554, 46.715005],
                        [10.394383, 46.70082],
                        [10.384771, 46.689012],
                        [10.373919, 46.681906],
                        [10.369165, 46.672398],
                        [10.377537, 46.653277],
                        [10.395623, 46.638808],
                        [10.438205, 46.635656],
                        [10.459082, 46.623564],
                        [10.466627, 46.604288],
                        [10.465903, 46.578476],
                        [10.457945, 46.553697],
                        [10.451833, 46.546702],
                        [10.443993, 46.537729],
                        [10.425906, 46.535326],
                        [10.354282, 46.548323],
                        [10.319452, 46.546049],
                        [10.306637, 46.547496],
                        [10.295371, 46.551087],
                        [10.289067, 46.555687],
                        [10.283796, 46.560725],
                        [10.275941, 46.565531],
                        [10.234703, 46.575298],
                        [10.230259, 46.58615],
                        [10.235737, 46.606691],
                        [10.233773, 46.617982],
                        [10.217856, 46.626974],
                        [10.192122, 46.626819],
                        [10.09745, 46.608035],
                        [10.087839, 46.604392],
                        [10.083498, 46.597002],
                        [10.071199, 46.564394],
                        [10.062931, 46.556746],
                        [10.04133, 46.541863],
                        [10.032752, 46.532975],
                        [10.031408, 46.525792],
                        [10.031201, 46.503829],
                        [10.02686, 46.493184],
                        [10.028101, 46.483934],
                        [10.030478, 46.476673],
                        [10.035335, 46.471066],
                        [10.044017, 46.466984],
                        [10.026344, 46.446262],
                        [10.042053, 46.432722],
                        [10.071405, 46.424816],
                        [10.116157, 46.418822],
                        [10.133417, 46.414016],
                        [10.140755, 46.402905],
                        [10.13321, 46.381098],
                        [10.125976, 46.37438],
                        [10.104995, 46.361357],
                        [10.09745, 46.351642],
                        [10.092386, 46.338103],
                        [10.091766, 46.328956],
                        [10.095797, 46.320533],
                        [10.104892, 46.309371],
                        [10.14613, 46.280277],
                        [10.158945, 46.262449],
                        [10.14582, 46.243328],
                        [10.117914, 46.231133],
                        [10.075746, 46.220022],
                        [10.042673, 46.220487],
                        [10.041847, 46.24307],
                        [10.031718, 46.260072],
                        [9.992237, 46.284359],
                        [9.977561, 46.298105],
                        [9.97105, 46.320016],
                        [9.970636, 46.339808],
                        [9.964022, 46.356086],
                        [9.93901, 46.367455],
                        [9.918443, 46.37115],
                        [9.899013, 46.372158],
                        [9.855398, 46.366964],
                        [9.788839, 46.343296],
                        [9.768065, 46.33862],
                        [9.755249, 46.340532],
                        [9.730858, 46.350712],
                        [9.720109, 46.350893],
                        [9.709257, 46.342392],
                        [9.707293, 46.330972],
                        [9.708844, 46.319629],
                        [9.70843, 46.311748],
                        [9.693134, 46.297072],
                        [9.674324, 46.291801],
                        [9.559705, 46.292731],
                        [9.536451, 46.298622],
                        [9.515264, 46.308596],
                        [9.502551, 46.32074],
                        [9.482604, 46.35681],
                        [9.473716, 46.361874],
                        [9.451598, 46.370375],
                        [9.444364, 46.375284],
                        [9.4424, 46.380891],
                        [9.443847, 46.396136],
                        [9.437852, 46.492047],
                        [9.434648, 46.498326],
                        [9.426794, 46.497111],
                        [9.410671, 46.488895],
                        [9.403849, 46.482513],
                        [9.400335, 46.475407],
                        [9.395478, 46.469413],
                        [9.384626, 46.466416],
                        [9.377081, 46.468689],
                        [9.351553, 46.485484],
                        [9.350829, 46.497861],
                        [9.330986, 46.501504],
                        [9.282306, 46.49737],
                        [9.263186, 46.485122],
                        [9.245823, 46.461041],
                        [9.237968, 46.436547],
                        [9.24758, 46.423033],
                        [9.260912, 46.416651],
                        [9.262876, 46.406626],
                        [9.260292, 46.394017],
                        [9.260396, 46.379728],
                        [9.273831, 46.344252],
                        [9.275175, 46.331385],
                        [9.268974, 46.309371],
                        [9.239725, 46.266996],
                        [9.224842, 46.231184],
                        [9.215747, 46.221056],
                        [9.204172, 46.213563],
                        [9.192183, 46.209635],
                        [9.181331, 46.204054],
                        [9.17575, 46.194132],
                        [9.171099, 46.182609],
                        [9.163788, 46.172989],
                        [9.163244, 46.172273],
                        [9.090587, 46.138167],
                        [9.072087, 46.118892],
                        [9.068159, 46.105972],
                        [9.07033, 46.083441],
                        [9.067126, 46.071142],
                        [9.059168, 46.061789],
                        [9.049659, 46.057913],
                        [9.027748, 46.053107],
                        [9.002117, 46.03931],
                        [8.997776, 46.027941],
                        [9.015553, 45.993111],
                        [8.982686, 45.971975],
                        [8.980516, 45.969495],
                        [8.979793, 45.966911],
                        [8.980516, 45.964379],
                        [8.982686, 45.961847],
                        [8.993435, 45.95425],
                        [9.001703, 45.93606],
                        [9.010798, 45.926655],
                        [9.020514, 45.922779],
                        [9.042321, 45.919731],
                        [9.051726, 45.915545],
                        [9.063095, 45.898957],
                        [9.059271, 45.881955],
                        [9.034363, 45.848107],
                        [9.002427, 45.820718],
                        [8.972351, 45.824646],
                        [8.939588, 45.834826],
                        [8.900004, 45.826403],
                        [8.903725, 45.841802],
                        [8.909719, 45.853688],
                        [8.91375, 45.86609],
                        [8.912096, 45.883402],
                        [8.906515, 45.896476],
                        [8.898144, 45.90955],
                        [8.88078, 45.931099],
                        [8.870962, 45.947067],
                        [8.864451, 45.953424],
                        [8.857733, 45.957093],
                        [8.800372, 45.978538],
                        [8.785076, 45.982311],
                        [8.767919, 45.983086],
                        [8.769573, 45.985773],
                        [8.773397, 45.990579],
                        [8.790967, 46.018691],
                        [8.819596, 46.042927],
                        [8.834375, 46.066388],
                        [8.80895, 46.089746],
                        [8.793861, 46.093415],
                        [8.763165, 46.092898],
                        [8.747145, 46.094449],
                        [8.739497, 46.098066],
                        [8.732159, 46.107419],
                        [8.728983, 46.108233],
                        [8.723891, 46.109538],
                        [8.71769, 46.107523],
                        [8.702187, 46.097963],
                        [8.695055, 46.095172],
                        [8.677485, 46.095792],
                        [8.630873, 46.114706],
                        [8.611546, 46.119357],
                        [8.601831, 46.122819],
                        [8.538682, 46.187621],
                        [8.51026, 46.207878],
                        [8.482665, 46.217542],
                        [8.456517, 46.224828],
                        [8.43812, 46.23537],
                        [8.427165, 46.251442],
                        [8.423237, 46.275833],
                        [8.426648, 46.301568],
                        [8.442874, 46.353373],
                        [8.446285, 46.382183],
                        [8.445768, 46.412362],
                        [8.441634, 46.434945],
                        [8.427888, 46.44869],
                        [8.399156, 46.452179],
                        [8.385907, 46.450206],
                        [8.343449, 46.443885],
                        [8.316267, 46.433653],
                        [8.294976, 46.418046],
                        [8.286605, 46.40536],
                        [8.290429, 46.401122],
                        [8.297043, 46.397634],
                        [8.297457, 46.387506],
                        [8.291462, 46.378359],
                        [8.281541, 46.370116],
                        [8.270068, 46.364044],
                        [8.24175, 46.354123],
                        [8.192554, 46.309164],
                        [8.171883, 46.299191],
                        [8.128475, 46.292473],
                        [8.106874, 46.285548],
                        [8.087341, 46.271802],
                        [8.077315, 46.262035],
                        [8.073078, 46.253612],
                        [8.076592, 46.249736],
                        [8.09995, 46.235629],
                        [8.129509, 46.196044],
                        [8.132299, 46.159354],
                        [8.110595, 46.126953],
                        [8.066877, 46.100598],
                        [8.056025, 46.098066],
                        [8.035354, 46.096516],
                        [8.025329, 46.091141],
                        [8.018197, 46.080858],
                        [8.016027, 46.069385],
                        [8.015924, 46.058172],
                        [8.010653, 46.029698],
                        [8.008792, 46.027683],
                        [7.999077, 46.0128],
                        [7.998354, 46.010629],
                        [7.985848, 45.999312],
                        [7.978717, 45.995178],
                        [7.969105, 45.993111],
                        [7.898205, 45.981949],
                        [7.883782, 45.973869],
                        [7.872917, 45.959383],
                        [7.870201, 45.94037],
                        [7.84962, 45.939712],
                        [7.848389, 45.938076],
                        [7.845288, 45.927792],
                        [7.846115, 45.922573],
                        [7.843738, 45.919214],
                        [7.831232, 45.91446],
                        [7.825444, 45.914666],
                        [7.807564, 45.91849],
                        [7.780072, 45.918129],
                        [7.732013, 45.930376],
                        [7.722195, 45.929601],
                        [7.71465, 45.92712],
                        [7.706279, 45.925725],
                        [7.69398, 45.928671],
                        [7.692533, 45.931203],
                        [7.673722, 45.950323],
                        [7.658736, 45.960038],
                        [7.643027, 45.966343],
                        [7.541121, 45.984119],
                        [7.524377, 45.978073],
                        [7.514662, 45.966704],
                        [7.503707, 45.956731],
                        [7.482726, 45.954871],
                        [7.452961, 45.945879],
                        [7.393843, 45.9157],
                        [7.361803, 45.907845],
                        [7.286666, 45.913426],
                        [7.27354, 45.910274],
                        [7.245428, 45.89813],
                        [7.183726, 45.880456],
                        [7.153547, 45.876529],
                        [7.120888, 45.876116],
                        [7.090192, 45.880508],
                        [7.066938, 45.890223],
                        [7.022083, 45.92526],
                        [7.015158, 45.933321],
                        [7.009784, 45.943398],
                        [7.002756, 45.961692],
                        [6.991283, 45.982466],
                        [6.987666, 45.993111],
                        [6.982808, 45.995385],
                        [6.915112, 46.048612],
                        [6.892375, 46.055588],
                        [6.884003, 46.053211],
                        [6.876872, 46.048095],
                        [6.869224, 46.044064],
                        [6.859715, 46.044994],
                        [6.85093, 46.049645],
                        [6.85031, 46.052746],
                        [6.852377, 46.056931],
                        [6.851964, 46.064683],
                        [6.853411, 46.065665],
                        [6.853101, 46.076103],
                        [6.851344, 46.086025],
                        [6.848553, 46.085043],
                        [6.853101, 46.090211],
                        [6.861162, 46.097032],
                        [6.86819, 46.10468],
                        [6.869224, 46.112329],
                        [6.853927, 46.122612],
                        [6.774346, 46.134808],
                        [6.765664, 46.151603],
                        [6.774863, 46.185864],
                        [6.792226, 46.221676],
                        [6.827676, 46.269477],
                        [6.804938, 46.296607],
                        [6.769488, 46.322678],
                        [6.750368, 46.345518],
                        [6.755742, 46.357068],
                        [6.782097, 46.378462],
                        [6.789229, 46.395205],
                        [6.788107, 46.405008],
                        [6.787058, 46.414171],
                        [6.777716, 46.424106],
                        [6.762667, 46.42926],
                        [6.613684, 46.455899],
                        [6.547021, 46.457372],
                        [6.482942, 46.448587],
                        [6.397676, 46.408176],
                        [6.365223, 46.40244],
                        [6.332357, 46.401381],
                        [6.301558, 46.394482],
                        [6.269105, 46.375026],
                        [6.24058, 46.348955],
                        [6.219496, 46.329111],
                        [6.214122, 46.315469],
                        [6.218256, 46.305495],
                        [6.227454, 46.288494],
                        [6.227971, 46.284463],
                        [6.237583, 46.267926],
                        [6.24182, 46.263689],
                        [6.252259, 46.259916],
                        [6.269002, 46.265239],
                        [6.27603, 46.26312],
                        [6.281198, 46.240073],
                        [6.255359, 46.221107],
                        [6.191384, 46.191704],
                        [6.140328, 46.150207],
                        [6.107875, 46.138632],
                        [6.073872, 46.149174],
                        [6.028293, 46.147934],
                        [5.982921, 46.140441],
                        [5.95884, 46.130467],
                        [5.972172, 46.152171],
                        [5.979821, 46.162248],
                        [5.982921, 46.170826],
                        [5.965248, 46.186226],
                        [5.954809, 46.19992],
                        [5.95853, 46.211961],
                        [5.982921, 46.222709],
                        [6.042866, 46.24307],
                        [6.044519, 46.243432],
                        [6.046173, 46.243535],
                        [6.048033, 46.243432],
                        [6.055888, 46.241675],
                        [6.061883, 46.241158],
                        [6.067671, 46.241623],
                        [6.089685, 46.246377],
                        [6.094026, 46.253044],
                        [6.093095, 46.262294],
                        [6.093612, 46.273094],
                        [6.100743, 46.301413],
                        [6.104051, 46.309216],
                        [6.118607, 46.331771],
                        [6.1364, 46.359342],
                        [6.135057, 46.370401],
                        [6.122861, 46.385542],
                        [6.108185, 46.396497],
                        [6.059019, 46.417383],
                        [6.054235, 46.419416],
                        [6.065707, 46.427012],
                        [6.067567, 46.433601],
                        [6.0655, 46.440371],
                        [6.0655, 46.447993],
                        [6.064777, 46.451068],
                        [6.0624, 46.455202],
                        [6.060229, 46.459904],
                        [6.060229, 46.46502],
                        [6.064157, 46.471118],
                        [6.075525, 46.479593],
                        [6.110355, 46.520831],
                        [6.145702, 46.55163],
                        [6.121517, 46.570285],
                        [6.118417, 46.583463],
                        [6.131853, 46.595607],
                        [6.266315, 46.680356],
                        [6.337938, 46.707409],
                        [6.34786, 46.71317],
                        [6.374215, 46.733609],
                        [6.407391, 46.745701],
                        [6.417933, 46.751101],
                        [6.429199, 46.760816],
                        [6.433023, 46.76911],
                        [6.432609, 46.785983],
                        [6.425168, 46.791615],
                        [6.419897, 46.796525],
                        [6.417107, 46.802157],
                        [6.418554, 46.807015],
                        [6.434263, 46.839545],
                        [6.441188, 46.848149],
                        [6.443118, 46.851455],
                        [6.446769, 46.857709],
                        [6.448422, 46.871559],
                        [6.445219, 46.882617],
                        [6.431886, 46.900032],
                        [6.427752, 46.909076],
                        [6.442635, 46.944164],
                        [6.491107, 46.963388],
                        [6.598698, 46.986539],
                        [6.665412, 47.021291],
                        [6.688253, 47.043848],
                        [6.676264, 47.0624],
                        [6.6897, 47.07829],
                        [6.699105, 47.084621],
                        [6.72422, 47.09077],
                        [6.72794, 47.097126],
                        [6.731661, 47.098883],
                        [6.746027, 47.103948],
                        [6.744787, 47.121053],
                        [6.774759, 47.128184],
                        [6.838076, 47.168132],
                        [6.840285, 47.169525],
                        [6.859302, 47.190919],
                        [6.888344, 47.211305],
                        [6.956247, 47.245231],
                        [6.952216, 47.270036],
                        [6.958624, 47.290551],
                        [6.977434, 47.303729],
                        [6.986529, 47.304504],
                        [6.991904, 47.305951],
                        [7.006476, 47.319361],
                        [7.016915, 47.323521],
                        [7.027147, 47.325433],
                        [7.036552, 47.329515],
                        [7.044303, 47.340497],
                        [7.033865, 47.350651],
                        [7.018879, 47.359901],
                        [7.003996, 47.368143],
                        [6.985599, 47.362123],
                        [6.86664, 47.354165],
                        [6.871601, 47.366955],
                        [6.884003, 47.382587],
                        [6.898783, 47.395713],
                        [6.924517, 47.405996],
                        [6.926068, 47.424858],
                        [6.952319, 47.428837],
                        [6.968546, 47.435194],
                        [6.983429, 47.443798],
                        [6.990973, 47.452221],
                        [6.986116, 47.464132],
                        [6.97578, 47.477956],
                        [6.9733, 47.489092],
                        [6.991904, 47.492942],
                        [7.000792, 47.49767],
                        [7.009784, 47.499247],
                        [7.018879, 47.49767],
                        [7.027974, 47.492942],
                        [7.053915, 47.490384],
                        [7.103731, 47.496275],
                        [7.127296, 47.492942],
                        [7.140318, 47.487852],
                        [7.142169, 47.487651],
                        [7.153651, 47.486405],
                        [7.180833, 47.488265],
                        [7.162642, 47.459895],
                        [7.168327, 47.443565],
                        [7.190031, 47.434728],
                        [7.219383, 47.428476],
                        [7.223517, 47.426228],
                        [7.226308, 47.422636],
                        [7.230339, 47.419019],
                        [7.23809, 47.416797],
                        [7.244808, 47.417727],
                        [7.282635, 47.428889],
                        [7.309093, 47.432661],
                        [7.33693, 47.431854],
                        [7.378547, 47.430646],
                        [7.388219, 47.433289],
                        [7.406349, 47.438242],
                        [7.420563, 47.450857],
                        [7.426089, 47.455761],
                        [7.429293, 47.465114],
                        [7.427639, 47.470747],
                        [7.422782, 47.475424],
                        [7.419474, 47.477852],
                        [7.41441, 47.484028],
                        [7.414307, 47.490177],
                        [7.425986, 47.492503],
                        [7.441488, 47.488834],
                        [7.445996, 47.486884],
                        [7.454511, 47.483201],
                        [7.46743, 47.481909],
                        [7.482726, 47.491263],
                        [7.484483, 47.492942],
                        [7.485827, 47.495784],
                        [7.48593, 47.498394],
                        [7.485776, 47.498768],
                        [7.484897, 47.5009],
                        [7.477765, 47.507696],
                        [7.475595, 47.511726],
                        [7.476939, 47.514879],
                        [7.482726, 47.516997],
                        [7.493062, 47.515499],
                        [7.501123, 47.517307],
                        [7.505464, 47.523018],
                        [7.505154, 47.533017],
                        [7.501743, 47.532965],
                        [7.485103, 47.541595],
                        [7.482726, 47.542267],
                        [7.520867, 47.563416],
                        [7.526341, 47.566452],
                        [7.550319, 47.575495],
                        [7.585483, 47.584479],
                        [7.586028, 47.584619],
                        [7.637032, 47.594977],
                        [7.659666, 47.596579],
                        [7.646541, 47.571542],
                        [7.635895, 47.564591],
                        [7.612337, 47.564731],
                        [7.609747, 47.564746],
                        [7.646901, 47.551445],
                        [7.661423, 47.546246],
                        [7.683438, 47.544257],
                        [7.72732, 47.550423],
                        [7.76674, 47.555961],
                        [7.78555, 47.563196],
                        [7.801467, 47.576089],
                        [7.819657, 47.595339],
                        [7.833713, 47.590481],
                        [7.898205, 47.587846],
                        [7.904303, 47.583557],
                        [7.907507, 47.574177],
                        [7.90947, 47.564798],
                        [7.912157, 47.560561],
                        [8.042279, 47.560561],
                        [8.087237, 47.567382],
                        [8.096952, 47.571852],
                        [8.101397, 47.576193],
                        [8.105427, 47.581257],
                        [8.113902, 47.587846],
                        [8.122067, 47.592135],
                        [8.143771, 47.600067],
                        [8.162065, 47.603762],
                        [8.168886, 47.608646],
                        [8.173847, 47.613529],
                        [8.179015, 47.615803],
                        [8.232965, 47.621952],
                        [8.251051, 47.622004],
                        [8.276993, 47.61663],
                        [8.288569, 47.615803],
                        [8.293943, 47.611462],
                        [8.299317, 47.601824],
                        [8.306345, 47.592187],
                        [8.316164, 47.587846],
                        [8.354094, 47.581024],
                        [8.41807, 47.580766],
                        [8.4211, 47.581112],
                        [8.448869, 47.58428],
                        [8.450109, 47.589034],
                        [8.461788, 47.606139],
                        [8.49238, 47.619833],
                        [8.522353, 47.621901],
                        [8.537752, 47.612134],
                        [8.549638, 47.598594],
                        [8.551719, 47.596863],
                        [8.560697, 47.589396],
                        [8.574133, 47.592445],
                        [8.576305, 47.595023],
                        [8.580644, 47.600171],
                        [8.581781, 47.607664],
                        [8.581264, 47.614769],
                        [8.582504, 47.62159],
                        [8.582091, 47.625027],
                        [8.579714, 47.628929],
                        [8.57806, 47.633476],
                        [8.580334, 47.639005],
                        [8.583951, 47.641124],
                        [8.589222, 47.642468],
                        [8.593563, 47.642571],
                        [8.594493, 47.640969],
                        [8.595113, 47.63482],
                        [8.601624, 47.632598],
                        [8.607309, 47.656291],
                        [8.598214, 47.656885],
                        [8.593589, 47.658169],
                        [8.582194, 47.66133],
                        [8.568241, 47.662932],
                        [8.519666, 47.657351],
                        [8.504679, 47.65226],
                        [8.49083, 47.645568],
                        [8.476051, 47.640401],
                        [8.458274, 47.639884],
                        [8.437603, 47.647842],
                        [8.411972, 47.661045],
                        [8.407011, 47.661562],
                        [8.391301, 47.665464],
                        [8.397709, 47.67629],
                        [8.395229, 47.684817],
                        [8.390991, 47.692129],
                        [8.392128, 47.699519],
                        [8.401947, 47.707089],
                        [8.427268, 47.716468],
                        [8.437913, 47.723186],
                        [8.445458, 47.743185],
                        [8.450109, 47.750471],
                        [8.463648, 47.763907],
                        [8.471503, 47.76706],
                        [8.482665, 47.766853],
                        [8.536512, 47.774088],
                        [8.551602, 47.779255],
                        [8.5423, 47.795017],
                        [8.558216, 47.801166],
                        [8.583124, 47.800236],
                        [8.601624, 47.794603],
                        [8.603175, 47.787317],
                        [8.604105, 47.774398],
                        [8.607619, 47.762254],
                        [8.617437, 47.757319],
                        [8.62984, 47.762796],
                        [8.635007, 47.784604],
                        [8.644102, 47.791012],
                        [8.657022, 47.788118],
                        [8.666633, 47.778273],
                        [8.674488, 47.766698],
                        [8.68193, 47.75874],
                        [8.692265, 47.757164],
                        [8.703324, 47.758714],
                        [8.713142, 47.757422],
                        [8.719757, 47.747319],
                        [8.71707, 47.743547],
                        [8.703737, 47.730033],
                        [8.70043, 47.723496],
                        [8.704667, 47.715332],
                        [8.712625, 47.708691],
                        [8.715106, 47.701069],
                        [8.71707, 47.694558],
                        [8.769883, 47.695074],
                        [8.761718, 47.70125],
                        [8.77071, 47.720861],
                        [8.797581, 47.720034],
                        [8.830241, 47.707192],
                        [8.856079, 47.690682],
                        [8.837682, 47.687788],
                        [8.837786, 47.680838],
                        [8.851935, 47.671282],
                        [8.852668, 47.670786],
                        [8.881711, 47.656136],
                        [8.906205, 47.651795],
                        [8.945376, 47.654302],
                        [8.981756, 47.662156],
                        [8.997673, 47.673835],
                        [9.016586, 47.6789],
                        [9.128104, 47.670425],
                        [9.183398, 47.670425],
                        [9.196937, 47.656136],
                        [9.234351, 47.656162],
                        [9.273211, 47.65009],
                        [9.547482, 47.534547],
                        [9.553059, 47.516891],
                        [9.554951, 47.5109],
                        [9.58451, 47.480721],
                        [9.621717, 47.469197],
                        [9.650346, 47.452092],
                        [9.649519, 47.409717],
                        [9.639804, 47.394524],
                        [9.601047, 47.36127],
                        [9.596396, 47.352305],
                        [9.591228, 47.334683],
                        [9.587404, 47.32781],
                        [9.553298, 47.299853],
                        [9.521155, 47.262801],
                        [9.504618, 47.243732],
                        [9.487358, 47.210014],
                        [9.484981, 47.176346],
                        [9.492629, 47.15981],
                        [9.503481, 47.145392],
                        [9.511853, 47.129372],
                        [9.51237, 47.10803],
                        [9.502861, 47.094698],
                        [9.487565, 47.083949],
                        [9.475886, 47.073226],
                        [9.477023, 47.063898],
                        [9.499554, 47.059351],
                        [9.560636, 47.0524],
                        [9.581203, 47.05687],
                        [9.59991, 47.053486],
                        [9.65231, 47.05793],
                        [9.669053, 47.056199],
                        [9.857982, 47.015478],
                        [9.856328, 47.004083],
                        [9.860566, 47.001602],
                        [9.866767, 47.001938],
                        [9.870591, 46.998838],
                        [9.870591, 46.992947],
                        [9.866457, 46.983387],
                        [9.863976, 46.959925],
                        [9.860772, 46.949151],
                        [9.862426, 46.939772],
                        [9.875138, 46.927421],
                        [9.899943, 46.914398],
                        [10.006913, 46.890757],
                        [10.045567, 46.865564],
                        [10.068098, 46.856624],
                        [10.1113, 46.847116],
                        [10.125188, 46.846751],
                        [10.13197, 46.846573],
                        [10.157808, 46.851612],
                        [10.201423, 46.86683],
                        [10.211655, 46.877036],
                        [10.214342, 46.884685],
                        [10.215169, 46.893108],
                        [10.219924, 46.905769],
                        [10.235116, 46.923313],
                        [10.251343, 46.92538],
                        [10.270773, 46.921892],
                        [10.295681, 46.922693],
                        [10.296198, 46.941374],
                        [10.313665, 46.964318],
                        [10.338883, 46.98411],
                        [10.367925, 46.995505],
                        [10.373403, 46.996254],
                        [10.378984, 46.995505],
                        [10.384255, 46.993153],
                        [10.384358, 46.993153],
                        [10.384358, 46.992998],
                        [10.394693, 46.985402],
                        [10.415571, 46.962406],
                        [10.449574, 46.943906],
                        [10.458462, 46.936619],
                        [10.463836, 46.919747],
                        [10.451434, 46.88577],
                        [10.453811, 46.864427]
                    ]
                ]
            },
            type: 'Feature',
            properties: {
                NAME_LEN: 11,
                NAME_FR: 'Suisse',
                ISO_A3: 'CHE',
                ISO_A2: 'CH',
                ABBREV_LEN: 6,
                FIPS_10_: 'SZ',
                ECONOMY: '2. Developed region: nonG7',
                ADM0_A3_US: 'CHE',
                NAME_NL: 'Zwitserland',
                NAME_SORT: 'Switzerland',
                TINY: -99,
                ADM0_DIF: 0,
                SUBREGION: 'Western Europe',
                ADM0_A3_UN: -99,
                NAME_RU: 'Швейцария',
                GDP_MD_EST: 496300,
                NAME_ES: 'Suiza',
                WIKIPEDIA: -99,
                GDP_YEAR: 2016,
                NAME_EL: 'Ελβετία',
                NAME_EN: 'Switzerland',
                BRK_DIFF: 0,
                NAME_VI: 'Thụy Sĩ',
                POP_RANK: 13,
                ISO_N3: '756',
                BRK_NAME: 'Switzerland',
                REGION_UN: 'Europe',
                REGION_WB: 'Europe & Central Asia',
                NOTE_BRK: null,
                SUBUNIT: 'Switzerland',
                GU_A3: 'CHE',
                NAME_HU: 'Svájc',
                MAPCOLOR7: 5,
                MAPCOLOR8: 2,
                MAPCOLOR9: 7,
                NAME_HI: 'स्विट्ज़रलैण्ड',
                FORMAL_FR: null,
                SU_A3: 'CHE',
                POP_EST: 8236303,
                GEOUNIT: 'Switzerland',
                HOMEPART: 1,
                NAME_ALT: null,
                NAME_PL: 'Szwajcaria',
                BRK_A3: 'CHE',
                NAME_PT: 'Suíça',
                WOE_ID: 23424957,
                scalerank: 0,
                LASTCENSUS: 2010,
                UN_A3: '756',
                LABELRANK: 4,
                NAME_SV: 'Schweiz',
                NAME_BN: 'সুইজারল্যান্ড',
                ABBREV: 'Switz.',
                MIN_ZOOM: 0,
                ADM0_A3_IS: 'CHE',
                NAME_JA: 'スイス',
                ADMIN: 'Switzerland',
                NAME_AR: 'سويسرا',
                SOVEREIGNT: 'Switzerland',
                ISO_A3_EH: 'CHE',
                MAX_LABEL: 9,
                NAME_IT: 'Svizzera',
                NAME: 'Switzerland',
                INCOME_GRP: '1. High income: OECD',
                SOV_A3: 'CHE',
                NOTE_ADM0: null,
                NAME_ID: 'Swiss',
                TYPE: 'Sovereign country',
                NAME_CIAWF: 'Switzerland',
                NAME_DE: 'Schweiz',
                ADM0_A3: 'CHE',
                WIKIDATAID: 'Q39',
                POSTAL: 'CH',
                LONG_LEN: 11,
                WOE_ID_EH: 23424957,
                LEVEL: 2,
                FORMAL_EN: 'Swiss Confederation',
                POP_YEAR: 2017,
                WOE_NOTE: 'Exact WOE match as country',
                ADM0_A3_WB: -99,
                CONTINENT: 'Europe',
                NAME_TR: 'İsviçre',
                GEOU_DIF: 0,
                MAPCOLOR13: 3,
                BRK_GROUP: null,
                SU_DIF: 0,
                featurecla: 'Admin-0 country',
                MIN_LABEL: 4,
                NAME_ZH: '瑞士',
                WB_A2: 'CH',
                WB_A3: 'CHE',
                NAME_KO: '스위스',
                NE_ID: 1159320491,
                NAME_LONG: 'Switzerland'
            }
        }
    ],
    style: { stroke: '#000000', fill: '#3498DB', 'stroke-width': 1, 'fill-opacity': 0.8 }
};
