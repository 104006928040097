import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { CookieConsent, SimulatorNavbar, Spinner, Stepper } from '../../components';

import { switzerlandBoundary } from './../../data/switzerlandBoundary';
import { steps } from './steps';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './../../assets/simulator.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoidGhvbWFzbXJxcyIsImEiOiJja2dueDA5aXAwNnpyMnptbnBrdDJ3MDhmIn0.G73fqMTOAdCg8LKBXIK29A';

const SimulatorLayout = () => {
    // MAP & DRAW
    const map = useRef(null);
    const mapContainer = useRef(null);
    const draw = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [drawControlAdded, setDrawControlAdded] = useState(false);
    const [mapDrawControl, activateMapDrawControl] = useState(false);
    const [mapDrawEnabled, setMapDrawEnabled] = useState(false);
    const [addressMapElements, setAddressMapElements] = useState({
        marker: null,
        circle: null
    });

    // FORM STATE
    const [formState, setFormState] = useState({
        completeAddress: null,
        address: '',
        buildingType: '',
        heatingType: '',
        electricityBill: '',
        firstName: '',
        lastName: '',
        phone: '',
        panelOrientation: 'vertical',
        numPanels: null,
        solarProduction: null,
        selection: null
    });

    // FUNCTIONS TO PASS TO STEPS (ON ENTER & ON EXIT METHODS)
    const functions = {
        activateSimulator: () => activateSimulator(),
        deactivateSimulator: () => deactivateSimulator()
    };

    // STEPS
    const stepsRef = useRef([]);
    const [step, setStep] = useState(0);
    const [previousStep, setPreviousStep] = useState(0);
    const lastStepIndex = steps.length - 1;
    const isLastStep = lastStepIndex === step;

    // INITIALIZE MAP (ONCE)
    useEffect(() => {
        if (map.current || !mapContainer.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: [8.33333333, 46.83333333], // Switzerland
            zoom: 7
        });

        // Initialize the draw control but not add it for now
        draw.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            },
            defaultMode: 'draw_polygon'
        });

        // SHOW SWITZERLAND BOUNDARY
        map.current.on('load', () => {
            map.current.addSource('switzerlandBoundary', {
                type: 'geojson',
                data: turf.mask(switzerlandBoundary)
            });

            map.current.addLayer({
                id: 'switzerlandBoundary',
                type: 'fill',
                source: 'switzerlandBoundary',
                paint: {
                    'fill-color': 'black',
                    'fill-opacity': 0.5
                }
            });
        });

        map.current.dragRotate.disable();
        map.current.touchZoomRotate.disableRotation();

        setMapLoaded(true);
    }, []);

    // SCROLL EFFECT & ON ENTER / ON EXIT METHODS EXECUTION (ON STEP CHANGE)
    useEffect(() => {
        if (stepsRef.current.length === 0) return;
        stepsRef.current[step].scrollIntoView({ behavior: 'smooth' });

        steps[step].onEnter && functions[steps[step].onEnter]();
        steps[previousStep].onExit && functions[steps[previousStep].onExit]();
    }, [step]);

    // SHOW ADDRESS MAP ELEMENTS
    useEffect(() => {
        if (isLastStep || (!addressMapElements.marker && !addressMapElements.circle)) return;

        showAddressMapElements();
    }, [addressMapElements]);

    // ADD / REMOVE DRAW CONTROL
    useEffect(() => {
        if (mapDrawControl) {
            map.current.addControl(draw.current);
            setDrawControlAdded(true);
        } else if (step !== 0 && !mapDrawControl && drawControlAdded) {
            map.current.removeControl(draw.current);
            setDrawControlAdded(false);
        }

        setMapDrawEnabled(mapDrawControl);
    }, [mapDrawControl]);

    const handleValueChange = (name, value) => {
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const onPrevClick = event => {
        event.preventDefault();
        setPreviousStep(step);
        setStep(Math.max(step - 1, 0));
    };

    const onStepSubmit = event => {
        event.preventDefault();
        setPreviousStep(step);
        setStep(Math.min(step + 1, lastStepIndex));
    };

    const addLayer = (data, type, options = {}) => {
        const prefix = options.prefix || '';
        const id = options.id || Math.floor(Math.random() * 100000);
        const paint = options.paint || {
            'fill-color': options.color
                ? typeof options.color === 'string'
                    ? options.color
                    : options.color[0]
                : 'red',
            'fill-outline-color': options.color
                ? typeof options.color === 'string'
                    ? options.color
                    : options.color[1]
                : 'red',
            'fill-opacity': options.opacity || 0.7
        };

        var idPrefix = `${prefix}-${id}`;
        var source = `source-${idPrefix}`;
        var layer = `layer-${idPrefix}`;

        // CHECK IF LAYER OR SOURCE ALREADY EXISTS
        while (map.current.getSource(source)) {
            source = `${source}-${prefix}${id + 1}`;
        }
        while (map.current.getLayer(layer)) {
            layer = `${layer}-${prefix}${id + 1}`;
        }

        map.current.addSource(source, {
            type: 'geojson',
            data: data
        });

        map.current.addLayer({
            id: layer,
            type: type,
            source: source,
            paint: paint
        });

        return id;
    };

    const clearMap = (remove = ['all', 'layers', 'markers', 'popups']) => {
        // REMOVE ALL LAYERS
        if (remove.includes('layers') || remove.includes('all')) {
            map.current.getStyle().layers.forEach(layer => {
                if (layer.id.startsWith('layer-')) {
                    map.current.removeLayer(layer.id);
                }
            });
        }

        // REMOVE ALL MARKERS
        if (remove.includes('markers') || remove.includes('all')) {
            const markers = Array.from(document.getElementsByClassName('mapboxgl-marker'));
            markers.forEach(marker => {
                marker.remove();
            });
        }

        // REMOVE ALL POPUPS
        if (remove.includes('popups') || remove.includes('all')) {
            const popups = Array.from(document.getElementsByClassName('mapboxgl-popup'));
            popups.forEach(popup => {
                popup.remove();
            });
        }
    };

    const showAddressMapElements = () => {
        if (!addressMapElements.marker && !addressMapElements.circle) return;
        clearMap();

        const marker = addressMapElements.marker;
        const circle = addressMapElements.circle;

        // ADD CIRCLE
        addLayer(circle, 'line', {
            paint: {
                'line-color': 'white',
                'line-width': 3,
                'line-opacity': 0.5
            }
        });
        addLayer(circle, 'fill', {
            paint: {
                'fill-color': 'red',
                'fill-opacity': 0.2,
                'fill-outline-color': 'white'
            }
        });

        // ADD MARKER
        new mapboxgl.Marker(marker).setLngLat(marker.getLngLat()).addTo(map.current);

        // MOVE MAP TO MARKER
        map.current.flyTo({
            center: marker.getLngLat(),
            zoom: 18,
            duration: 1000
        });
    };

    const activateSimulator = () => {
        deactivateSimulator();
        clearMap();
        activateMapDrawControl(true);
        map.current.flyTo({
            center: formState.completeAddress.features[0].geometry.coordinates,
            zoom: 20
        });
    };

    const deactivateSimulator = () => {
        activateMapDrawControl(false);
        clearMap(isLastStep ? ['popups'] : ['all']);
        !isLastStep && showAddressMapElements();
    };

    const renderStep = Step => (
        <Step
            map={map}
            draw={draw}
            clearMap={clearMap}
            formState={formState}
            mapDrawEnabled={mapDrawEnabled}
            mapLoaded={mapLoaded}
            handleValueChange={handleValueChange}
            setAddressMapElements={setAddressMapElements}
            showAddressMapElements={showAddressMapElements}
            activateMapDrawControl={activateMapDrawControl}
            activateSimulator={activateSimulator}
            deactivateSimulator={deactivateSimulator}
            addLayer={addLayer}
        />
    );

    const renderButtons = () => (
        <div
            className={`p-4 shadow-lg bg-white fixed-bottom d-flex justify-content-between ${
                isMobile ? 'w-100' : 'w-50'
            }`}
        >
            <Button variant='outline-primary' disabled={step === 0} onClick={onPrevClick}>
                <i className={`fa fa-arrow-left ${isMobile ? 'me-0' : 'me-2'}`}></i>
                {!isMobile && 'Précédent'}
            </Button>
            {isMobile && (
                <div className='position-absolute top-50 start-50 translate-middle'>
                    <Stepper steps={steps.length} currentStep={step + 1} size={20} lineWidth={10} />
                </div>
            )}
            {steps[step].nextButton && (
                <Button
                    disabled={
                        steps[step].nextButton.validationFields &&
                        steps[step].nextButton.validationFields.some(field => !formState[field])
                    }
                    onClick={onStepSubmit}
                >
                    {!isMobile && steps[step].nextButton.label}
                    <i className={`fa fa-arrow-right ${isMobile ? 'ms-0' : 'ms-2'}`}></i>
                </Button>
            )}
        </div>
    );

    const renderMap = () => (
        <div
            ref={mapContainer}
            className='map-container'
            style={{
                width: isMobile ? 'auto' : '100%',
                height: isMobile ? 'calc(40vh)' : 'calc(100vh - 75px)'
            }}
        />
    );

    return (
        <>
            <Spinner />
            <CookieConsent />
            <SimulatorNavbar steps={steps.length} currentStep={step} />
            {isMobile ? (
                <Container fluid className='m-0 p-0'>
                    <div
                        className='d-flex flex-column justify-content-between'
                        style={{ height: 'calc(100vh - 86px - 75px)' }}
                    >
                        <Col md={6} className='mx-0 mt-5 p-0'>
                            {steps.map(({ component: Step }, index) => (
                                <div
                                    key={index}
                                    ref={ref => (stepsRef.current[index] = ref)}
                                    className='d-flex flex-column justify-content-center align-content-center mx-md-5'
                                >
                                    {index === step && (
                                        <div
                                            style={{
                                                height: 'calc(100% - 86px)',
                                                marginBottom: steps[step].showMapOnMobile ? '0' : 'calc(86px + 10px)'
                                            }}
                                        >
                                            {renderStep(Step)}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Col>

                        <Col md={6} className={`m-0 p-0 ${steps[step].showMapOnMobile ? '' : 'd-none'}`}>
                            {renderMap()}
                        </Col>

                        {renderButtons()}
                    </div>
                </Container>
            ) : (
                <Container fluid className='m-0 p-0'>
                    <Row className='m-0 p-0' style={{ height: 'calc(100vh - 86px)' }}>
                        <Col md={6} className='m-0 p-0'>
                            <div className='overflow-y-hidden' style={{ height: 'calc(100vh - 75px)' }}>
                                {steps.map(({ component: Step }, index) => (
                                    <div
                                        key={index}
                                        ref={ref => (stepsRef.current[index] = ref)}
                                        className='d-flex flex-column justify-content-center align-content-center mx-5'
                                        style={{ height: 'calc(100% - 86px)', marginBottom: isLastStep ? '86px' : '0' }}
                                    >
                                        {index === step && renderStep(Step)}
                                    </div>
                                ))}
                            </div>
                            {renderButtons()}
                        </Col>

                        <Col md={6} className='m-0 p-0'>
                            {renderMap()}
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default SimulatorLayout;
