import emailjs from '@emailjs/browser';
import { useState } from 'react';
import { Accordion, Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { StepProps } from './types';

import 'react-phone-number-input/style.css';

const Step7 = ({ formState, handleValueChange }: StepProps) => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs
            .send(
                'service_qsmoznc',
                'template_163bj1h',
                {
                    lastname: formState.lastName,
                    firstname: formState.firstName,
                    email: '-',
                    phone: formState.phone,
                    subject: 'Demande de rappel via simulateur',
                    message: `Informations de la simulation :<br />Adresse : ${formState.address}<br />Type de bâtiment : ${formState.buildingType}<br />Type de chauffage : ${formState.heatingType}<br />Montant facture d'électricité annuelle : ${formState.electricityBill}<br />Nombre de panneaux solaires via la simulation : ${formState.numPanels}<br />Orientation des panneaux solaires : ${formState.panelOrientation}<br />`,
                    date: new Date().toLocaleDateString('fr')
                },
                'By0mP_22032uCbEPG'
            )
            .then(_ => {
                setSuccess(true);
            })
            .catch(error => {
                console.error(error);
                setError(true);
            });
    };

    return (
        <Container className='overflow-y-scroll confirmation-container'>
            <h2>Félicitations !</h2>
            <p>
                Merci d'avoir utilisé notre simulateur de pose de panneaux solaires. Vous êtes prêt à économiser de
                l'énergie proprement. Voici votre résultat :
            </p>

            <Alert variant='success'>
                <h3>
                    Estimation de votre production solaire :{' '}
                    <span className='display-4'>
                        {formState.solarProduction}
                        <small className='fs-6'>kWh</small>
                    </span>
                </h3>
                <small className='text-muted fw-lighter fst-italic confirmation-help-text'>
                    Ce résultat est une estimation basée sur les informations que vous avez fournies. Veuillez noter que
                    ces chiffres sont fournis à titre indicatif, vous obtiendrez une estimation plus précise lors de
                    votre visite technique.
                </small>
            </Alert>

            <Form onSubmit={sendEmail}>
                <Form.Label>
                    <strong>Planifiez votre séance téléphonique sous 24 heures avec notre bureau d'étude :</strong>
                </Form.Label>
                <Row className='mb-2'>
                    <Form.Group as={Col} md={6} controlId='firstName'>
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Entrez votre prénom'
                            value={formState.firstName}
                            onChange={e => handleValueChange('firstName', e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={6} controlId='lastName'>
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Entrez votre nom'
                            value={formState.lastName}
                            onChange={e => handleValueChange('lastName', e.target.value)}
                            required
                        />
                    </Form.Group>
                </Row>
                <Form.Group className='mb-4' controlId='phone'>
                    <Form.Label>Natel / Téléphone</Form.Label>
                    <PhoneInput
                        international
                        defaultCountry='CH'
                        placeholder='Natel / Téléphone'
                        className='form-control'
                        value={formState.phone}
                        onChange={value => handleValueChange('phone', value)}
                        required
                    />
                </Form.Group>
                <Button variant='primary' type='submit' className='float-end'>
                    Envoyer
                </Button>
                <div className='clearfix'></div>
                {success && (
                    <Alert variant='success' className='mt-4'>
                        Votre demande a bien été envoyée. Nous vous contacterons dans les plus brefs délais.
                    </Alert>
                )}
                {error && (
                    <Alert variant='danger' className='mt-4'>
                        Une erreur est survenue lors de l'envoi de votre demande. Veuillez réessayer.
                    </Alert>
                )}
                <Accordion className='mt-4'>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Header>Protection de vos données personnelles : notre engagement</Accordion.Header>
                        <Accordion.Body className='text-muted fw-lighter fst-italic'>
                            Nous prenons très au sérieux la protection de vos données personnelles. Les informations que
                            vous fournissez, telles que votre nom, prénom et numéro de téléphone, seront utilisées
                            uniquement dans le but de vous fournir un service personnalisé et de répondre à vos besoins
                            spécifiques. Vos données seront traitées conformément à notre politique de confidentialité,
                            et nous nous engageons à ne jamais les partager ni les vendre à des tiers sans votre
                            consentement explicite. La sécurité de vos données est notre priorité, et nous mettons en
                            œuvre des mesures de protection avancées pour garantir leur intégrité et leur
                            confidentialité.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Form>
        </Container>
    );
};

export default Step7;
