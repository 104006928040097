import { useEffect, useState } from 'react';

const Spinner = () => {
    const [spinner, setSpinner] = useState<boolean>(true);

    useEffect(() => {
        setTimeout(function () {
            spinner && setSpinner(false);
        }, 500);
    }, [spinner]);

    if (!spinner) return null;

    return (
        <div
            id='spinner'
            className='show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center'
        >
            <div className='spinner-border text-primary' style={{ width: '3rem', height: '3rem' }} role='status'>
                <span className='sr-only'>Loading...</span>
            </div>
        </div>
    );
};

export default Spinner;
