import { Card } from 'react-bootstrap';

const steps = [
    {
        icon: 'home',
        label: 'Visite, études et solutions techniques'
    },
    {
        icon: 'list-check',
        label: "Démarches d'autorisations et subventions"
    },
    {
        icon: 'gears',
        label: 'Installation, raccordement et mise en service'
    },
    {
        icon: 'square-check',
        label: 'Contrôles finaux'
    }
];

const Step = ({ icon, label, index = 1 }: { icon: string; label: string; index: number }) => (
    <Card
        className='step-card shadow border-0 p-2 wow animate__fadeInUp'
        data-wow-offset='100'
        data-wow-duration={`${1 + index * 0.2}s`}
    >
        <Card.Body className='d-flex flex-column text-center lh-sm'>
            <i className={`fa fa-${icon}`}></i>
            <span className='mt-3'>{label}</span>
        </Card.Body>
    </Card>
);

const Steps = () => {
    return (
        <div className='mb-4 d-flex flex-wrap justify-content-center'>
            {steps.map((step, index) => (
                <>
                    <div className='wow animate__fadeIn m-2' data-wow-duration='1s'>
                        <Step index={index} icon={step.icon} label={step.label} />
                    </div>
                    {index < steps.length - 1 && (
                        <>
                            <div className='break-line'></div>
                            <div className='d-flex justify-content-center align-items-center mx-5'>
                                <i className='fa fa-chevron-down fa-3x d-block d-md-none'></i>
                                <i className='fa fa-chevron-right fa-3x d-none d-md-block'></i>
                            </div>
                            <div className='break-line'></div>
                        </>
                    )}
                    {/* <Col xs={12} md={2} className='wow animate__fadeIn' data-wow-duration='1s'>
                        <Step index={index} icon={step.icon} label={step.label} />
                    </Col>
                    {index < steps.length - 1 && (
                        <Col xs={12} md={1} className='d-flex justify-content-center align-items-center'>
                            {isMobile ? (
                                <i className='fa fa-chevron-down fa-3x'></i>
                            ) : (
                                <i className='fa fa-chevron-right fa-3x'></i>
                            )}
                        </Col>
                    )} */}
                </>
            ))}
        </div>
    );
};

export default Steps;
