import { AddressAutofill } from '@mapbox/search-js-react';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

const Step1 = ({ map, formState, mapLoaded, handleValueChange, clearMap, setAddressMapElements }) => {
    // EVENT LISTENERS
    useEffect(() => {
        if (!map.current) return;

        map.current.dragPan.enable();
        map.current.on('click', addCorrectPositionMarker);

        return () => {
            if (!map.current) return;
            map.current.off('click', addCorrectPositionMarker);
        };
    }, [mapLoaded]);

    useEffect(() => {
        if (!formState.completeAddress) return;

        handleValueChange(
            'address',
            formState.completeAddress.geocoding_type === 'forward'
                ? formState.completeAddress.features[0].properties.full_address
                : formState.completeAddress.features[0].place_name
        );
    }, [formState.completeAddress]);

    const addCorrectPositionMarker = (event, searchAddress = true) => {
        clearMap();

        const lngLat = event.lngLat;

        // ADD NEW MARKER
        const marker = new mapboxgl.Marker().setLngLat(lngLat);

        // ADD CIRCLE AROUND MARKER
        const circle = turf.circle(turf.point([lngLat.lng, lngLat.lat]), 30, { units: 'meters' });

        setAddressMapElements({ marker, circle });

        // SEARCH ADDRESS FROM COORDINATES
        if (searchAddress) {
            fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?country=CH&language=fr&access_token=${mapboxgl.accessToken}`
            )
                .then(response => response.json())
                .then(data => {
                    handleValueChange('completeAddress', { geocoding_type: 'reverse', ...data });
                    handleValueChange('address', data.features[0].place_name);
                });
        }
    };

    const handleAddressRetrieval = address => {
        // MOVE MAP TO ADDRESS
        map.current.flyTo({
            center: [address.features[0].geometry.coordinates[0], address.features[0].geometry.coordinates[1]],
            zoom: 18,
            duration: 1000
        });

        handleValueChange('completeAddress', { geocoding_type: 'forward', ...address });
        addCorrectPositionMarker(
            {
                lngLat: {
                    lng: address.features[0].geometry.coordinates[0],
                    lat: address.features[0].geometry.coordinates[1]
                }
            },
            false
        );
    };

    return (
        <Form className='d-flex flex-column justify-content-center align-content-center mx-5'>
            <h2 className='mb-4'>Votre simulation de pose de panneau solaire personnalisée</h2>
            {isMobile ? (
                <p>Bienvenue dans notre simulateur de pose de panneau solaire !</p>
            ) : (
                <p>
                    Bienvenue dans notre simulateur de pose de panneau solaire ! <br />
                    Pour obtenir un kit solaire sur-mesure parfaitement adapté à vos besoins, commencez par saisir
                    l'adresse de votre future installation.
                </p>
            )}
            <AddressAutofill
                accessToken={mapboxgl.accessToken}
                options={{
                    language: 'fr',
                    country: 'CH'
                }}
                onRetrieve={address => handleAddressRetrieval(address)}
            >
                <Form.Group controlId='address'>
                    <Form.Control
                        type='text'
                        placeholder="Saisissez l'adresse de votre future installation ici"
                        value={formState.address}
                        onChange={e => handleValueChange('address', e.target.value)}
                        required
                        autoComplete='address-line1'
                    />
                    <Form.Text className='text-muted d-none d-md-block'>
                        Cette étape essentielle nous permettra de personnaliser votre simulation en fonction de votre
                        emplacement géographique.
                    </Form.Text>
                </Form.Group>
            </AddressAutofill>
        </Form>
    );
};

export default Step1;
