import HuaweiSmartCharger from './../assets/images/produits/chargeurs-voiture/huawei/car-charger-huawei.jpg';
import LogoHuawei from './../assets/images/produits/chargeurs-voiture/huawei/logo-huawei.png';
import PdfHuaweiSmartCharger from './../assets/images/produits/chargeurs-voiture/huawei/pdf/Huawei_Wallbox_Smart_Charge_AC_7kW_22kW.pdf';
import SolarEdgeVE from './../assets/images/produits/chargeurs-voiture/solaredge/car-charger-solaredge.jpg';
import PdfSolarEdgeVE from './../assets/images/produits/chargeurs-voiture/solaredge/pdf/Fiche-technique-solaredge-home-chargeur-ve-22-kw.pdf';
import ThomsonEOne from './../assets/images/produits/chargeurs-voiture/thomson/E-one2.jpg';
import LogoThomson from './../assets/images/produits/chargeurs-voiture/thomson/logo-thomson.png';
import PdfThomsonEOne from './../assets/images/produits/chargeurs-voiture/thomson/pdf/Fiche-E-One.pdf';
import WaterHeater from './../assets/images/produits/eau-chaude-sanitaire/chauffe-eau.jpg';
import BallonVaillant from './../assets/images/produits/eau-chaude-sanitaire/vaillant/arastor.jpg';
import LogoVaillant from './../assets/images/produits/eau-chaude-sanitaire/vaillant/logo-vaillant.png';
import PdfVaillantArostor from './../assets/images/produits/eau-chaude-sanitaire/vaillant/pdf/brochure-arostor.pdf';
import PdfClickFitEVO from './../assets/images/produits/fixations/ESDEC/Brochure_ClickFitEvo_TiledRoof_TrussHook_FR_249.pdf';
import LogoESDEC from './../assets/images/produits/fixations/ESDEC/logo-esdec.png';
import FixationClickFitEVO from './../assets/images/produits/fixations/fixation-ClickFit-EVO.jpg';
import FixationK2 from './../assets/images/produits/fixations/fixation-k2.jpg';
import PdfInsertInfos from './../assets/images/produits/fixations/k2/Insert-infos-fr.pdf';
import PdfSingleRailInfos from './../assets/images/produits/fixations/k2/SingleRail-infos-fr.pdf';
import PdfSolidRailInfos from './../assets/images/produits/fixations/k2/SolidRail-infos-fr.pdf';
import LogoK2Systems from './../assets/images/produits/fixations/k2/logo-K2-Systems.png';
import LogoFermacell from './../assets/images/produits/isolation/fermacell/fermacell-logo.png';
import IsolationFermacel from './../assets/images/produits/isolation/fermacell/isolation-fermacell.webp';
import Isolation from './../assets/images/produits/isolation/isolation.png';
import IsolationSwisspor from './../assets/images/produits/isolation/swisspor/isolation-swisspor.jpg';
import LogoSwisspor from './../assets/images/produits/isolation/swisspor/logo-swisspor.png';
import LogoAPSystemes from './../assets/images/produits/onduleurs/AP-systems/logo-APsystems.png';
import InverterDS3 from './../assets/images/produits/onduleurs/AP-systems/onduleurs-ds3.jpg';
import PdfMicroInverter from './../assets/images/produits/onduleurs/AP-systems/pdf/APsystems-Microinverter-DS3.pdf';
import LogoEnphase from './../assets/images/produits/onduleurs/enphase/logo-enphase.png';
import InverterEnphase from './../assets/images/produits/onduleurs/enphase/onduleur-enphase.jpg';
import PdfIQ8Plus from './../assets/images/produits/onduleurs/enphase/pdf/IQ8PLUS-DS-V1-MC4-FR-EU-2022-11-10.pdf';
import LogoSolarEdge from './../assets/images/produits/onduleurs/solaredge/logo-solaredge.png';
import InverterSolarEdge from './../assets/images/produits/onduleurs/solaredge/onduleur-solaredge.jpg';
import PdfSolarEdgeInverterWaveHome from './../assets/images/produits/onduleurs/solaredge/pdf/Onduleur_Wave_SolarEdge_Home_triphase.pdf';
import LogoMegasol from './../assets/images/produits/panneaux-photovoltaiques/megasol/logo_megasol.png';
import PanneauxMegasol from './../assets/images/produits/panneaux-photovoltaiques/megasol/panneaux-megasol.jpg';
import PdfMegasolM385 from './../assets/images/produits/panneaux-photovoltaiques/megasol/pdf/M385-HC120-b_BF_GG_U30b.pdf';
import PdfMegasolM340 from './../assets/images/produits/panneaux-photovoltaiques/megasol/pdf/Swiss_Premium_M340-60-b_GG3.pdf';
import LogoSoluxtec from './../assets/images/produits/panneaux-photovoltaiques/soluxtec/logo_soluxtec.png';
import PanneauSoluxtec from './../assets/images/produits/panneaux-photovoltaiques/soluxtec/panneau-soluxtec.jpg';
import PdfSoluxtecDMMXSCNI from './../assets/images/produits/panneaux-photovoltaiques/soluxtec/pdf/DMMXSCNi_415-425_PG_BB_FR_21062023.pdf';
import AlphaInnotecLWD from './../assets/images/produits/pompes-a-chaleur/alpha-innotec/alpha_innotec_LWD_2.jpg';
import LogoAlphaInnotec from './../assets/images/produits/pompes-a-chaleur/alpha-innotec/logo-alphainnotec.png';
import DaikinAltherma from './../assets/images/produits/pompes-a-chaleur/daikin/daikin-altherma.png';
import LogoDaikin from './../assets/images/produits/pompes-a-chaleur/daikin/logo-daikin.png';

export const productsByCategory: ProductsByCategory = {
    solarPanels: {
        title: 'Panneaux solaires',
        path: 'panneaux-solaires-photovoltaiques',
        description: 'Misez sur la performance avec nos panneaux solaires de qualité supérieure MegaSol et Munchen.',
        icon: 'solar-panel',
        image: PanneauxMegasol,
        bannerClass: 'panneau',
        productsByBrand: {
            Megasol: {
                logo: LogoMegasol,
                image: PanneauxMegasol,
                products: [
                    {
                        title: 'Panneau de haute puissance M385-HC120-b BF GG U30b',
                        description: '',
                        specs: [
                            'Technologie n-type HiR half-cut',
                            'Facteur de bifacialité accru (rendements supplémentaires)',
                            'Stabilité de puissance élevée et efficacité de pointe',
                            'Très haut niveau esthétique',
                            'Durée de vie élevée grâce à la technologie bi-verre',
                            'Développement et garantie suisse'
                        ],
                        technicalSheet: PdfMegasolM385
                    },
                    {
                        title: 'Swiss Premium M340-60-b GG3',
                        description: '',
                        specs: [
                            'Made in Deitingen (Suisse)',
                            'Technologie n-type HiR',
                            'Très haut niveau esthétique',
                            'Verre de sécurité pour vitrages et façades',
                            "La salissure et la neige n'adhèrent pas",
                            'Durée de vie élevée grâce à la technologie bi-verre'
                        ],
                        technicalSheet: PdfMegasolM340
                    }
                ]
            },
            Soluxtec: {
                logo: LogoSoluxtec,
                image: PanneauSoluxtec,
                products: [
                    {
                        title: 'DAS MODUL MONO XSC Ninside Serie Black',
                        description: 'DMMXSCNi415 | DMMXSCNi420 | DMMXSCNi425',
                        specs: [
                            'Cellules TOPCON N Type',
                            "Offre un rendement allant jusqu'à 21,7 %.",
                            "Totalement neutre à la dégradation par l’effet LID grâce à l'absence de recombinaison bore-oxygène des cellules Ninside. ",
                            'PID résistant.',
                            "Garantie d'efficacité supérieure à 89 % la 25 années.",
                            'Nouveau design du cadre dévoilant un alliage renforcé.',
                            'Certifié CE, IEC, Air Salin, NH3 et PID',
                            'Module garantie 25 ans'
                        ],
                        technicalSheet: PdfSoluxtecDMMXSCNI
                    }
                ]
            }
        }
    },
    fixations: {
        title: 'Fixations',
        path: 'fixations',
        description: 'Nous proposons des systèmes de fixation robustes de marques renommées comme K2 Systems et ESDEC.',
        icon: 'wrench',
        image: FixationK2,
        bannerClass: 'fixation',
        showInHero: false,
        productsByBrand: {
            'K2 Systems': {
                title: 'Systèmes de fixation Inserts, SolidRail & SingleRail',
                logo: LogoK2Systems,
                description:
                    'Avec les systèmes de montage K2 Systems, nous pouvons mettre en œuvre une installation photovoltaïque solide, sûre et durable, pour tout type de couverture.',
                image: FixationK2,
                products: [
                    {
                        title: 'Système Insert',
                        description:
                            "Installation ultra rapide des modules, sans vis, sans étrier ni assistance, peu de composants, montage facile sans outil spécial, design lisse et continu du rail : Ce système d'insertion renforce les cadres des modules.",
                        technicalSheet: PdfInsertInfos
                    },
                    {
                        title: 'Système SingleRail',
                        description:
                            'Rapide et économique avec fixation latérale directe sur le rail sans pièce de construction supplémentaire et réglage individuel en hauteur. Montage sécurisé par clipsage – pas de vissage sur la plaque de base : le SingleRail associé aux crochets CrossHook est très résistant et modulable.',
                        technicalSheet: PdfSingleRailInfos
                    },
                    {
                        title: 'Système SolidRail',
                        description:
                            'Large gamme de rails de montage pour les types de charge les plus divers. Robuste et calculé selon les normes statiques en vigueur. Grande flexibilité pour différentes portées.',
                        technicalSheet: PdfSolidRailInfos
                    }
                ]
            },
            'ESDEC Innovative Mouning Systems': {
                title: 'ClickFit® EVO',
                logo: LogoESDEC,
                description:
                    'Avec ClickFit® EVO Toit en tuiles, nous montons des panneaux solaires sur tous les types de toits en tuiles inclinés. Grâce à sa conception universelle, ce système fonctionne beaucoup plus facilement et rapidement (jusqu’a 40% plus rapide) que les autres systèmes de montage.',
                image: FixationClickFitEVO,
                products: [
                    {
                        title: 'Fixations ESDEC ClickFit',
                        description:
                            'Système de fixation rapide et fiable pour panneaux solaires sur différents types de toits.',
                        specs: [
                            'Convient à tous les toits en tuiles',
                            'Paysage et portrait possibles',
                            'Système de clic pratique'
                        ],
                        technicalSheet: PdfClickFitEVO
                    }
                ]
            }
        }
    },
    inverters: {
        title: 'Onduleurs',
        path: 'onduleurs',
        description:
            "Optez pour la fiabilité avec des onduleurs de marques de confiance telles qu'Enphase, SolarEdge, APSystems et Huawei.",
        icon: 'wave-square',
        image: InverterEnphase,
        bannerClass: 'onduleur',
        productsByBrand: {
            ENPHASE: {
                title: "L'onduleur Enphase IQ8+ Microinverter haute puissance est conçu pour être prêt pour les réseaux intelligents.",
                logo: LogoEnphase,
                image: InverterEnphase,
                products: [
                    {
                        title: 'IQ8+ Microinverter',
                        description:
                            'Son cerveau à base de semi-conducteurs est un circuit intégré spécifique à l’application (ASIC) exclusif qui permet au micro-onduleur de fonctionner en mode connecté au réseau ou hors réseau.',
                        specs: [
                            'Rendement maximal: 300VA avec 230Vc.a.',
                            'Rendement Euro élevé de 97,1%',
                            'Plus d’1 million d’heures sous tension de tests de fiabilité',
                            'Léger et compact pour une installation facile',
                            'Technologie brevetée Burst Mode (mode rafale)'
                        ],
                        technicalSheet: PdfIQ8Plus
                    }
                ]
            },
            SolarEdge: {
                title: 'L’avenir des onduleurs solaires en matière de gestion intelligente de l’énergie',
                logo: LogoSolarEdge,
                image: InverterSolarEdge,
                products: [
                    {
                        title: 'Onduleur Wave SolarEdge Home Triphasé',
                        description: 'SE3K - SE10K',
                        specs: [
                            'Niveau sonore adapté à l’environnement résidentiel – Pas de ventilateur externe',
                            'Rendement supérieur (98 %)',
                            'Compact, le plus léger de sa catégorie et facile à installer',
                            'Supervision intégrée au niveau du module',
                            'IP65 – Installation en extérieur et intérieur'
                        ],
                        technicalSheet: PdfSolarEdgeInverterWaveHome
                    }
                ]
            },
            APsystems: {
                title: 'Les micro-onduleurs duo les plus puissants',
                logo: LogoAPSystemes,
                description:
                    "APSystems présente la 3ème génération de micro-onduleurs doubles, atteignant des puissances sans précédent pour s'adapter aux modules PV d'aujourd'hui.",
                image: InverterDS3,
                products: [
                    {
                        title: 'Micro-onduleur APsystems DS3',
                        description:
                            "APSystems présente la 3ème génération de micro-onduleurs doubles, atteignant des puissances sans précédent pour s'adapter aux modules PV d'aujourd'hui.",
                        specs: [
                            'Un micro-onduleur connecte deux modules photovoltaïques',
                            'Puissance de sortie maximum de 730VA, 880VA, ou 960VA',
                            'Un MPPT pour chaque module',
                            'Facteur de puissance ajustable (RPC)',
                            'Fiabilité maximum, IP67',
                            'Communications Zigbee cryptées',
                            'Relais VDE intégrés'
                        ],
                        technicalSheet: PdfMicroInverter
                    }
                ]
            }
        }
    },
    chargingStation: {
        title: 'Bornes de recharge',
        path: 'bornes-de-recharge',
        description:
            'Chargez votre véhicule électrique en toute simplicité avec les chargeurs Thomson, SolarEdge et Huawei.',
        icon: 'charging-station',
        image: ThomsonEOne,
        bannerClass: 'chargeur',
        productsByBrand: {
            SolarEdge: {
                logo: LogoSolarEdge,
                image: SolarEdgeVE,
                products: [
                    {
                        title: 'Borne de recharge VE SolarEdge',
                        description:
                            "Une solution de recharge VE résidentielle qui s'intègre de manière transparente à l'ensemble de l'écosystème SolarEdge",
                        specs: [
                            'Station de charge Mode 3',
                            "Puissance de charge jusqu'à 32A (22 kW)",
                            "Convient aux installations triphasées, tant à l'intérieur qu'à l'extérieur",
                            "Contrôle et surveillance via l'application mySolarEdge, y compris les opérations à distance, la programmation intelligente et l'historique de charge"
                        ],
                        technicalSheet: PdfSolarEdgeVE
                    }
                ]
            },
            Thomson: {
                logo: LogoThomson,
                image: ThomsonEOne,
                products: [
                    {
                        title: 'E-ONE',
                        description:
                            'La borne de recharge Thomson E-One est conçue pour une utilisation domestique pour maisons individuelles. Elle s’intègre élégamment dans son environnement grâce à son esthétisme épuré et son ergonomie soignée.',
                        specs: [
                            'Facile à installer et à utiliser',
                            'Pilotage intelligent de la recharge jusqu’à 7,4 kW',
                            'Qualification IRVE 1'
                        ],
                        technicalSheet: PdfThomsonEOne
                    }
                ]
            },
            Huawei: {
                logo: LogoHuawei,
                image: HuaweiSmartCharger,
                products: [
                    {
                        title: 'Huawei Wallbox Chargeur Intelligent AC 3 phases 22KT-S0',
                        description:
                            "Chargeur intelligent Huawei Wallbox 22KT-S0 est un chargeur dynamique triphasé jusqu'à 22kW/32A et une prise de charge automatique de type 1 à commutation triphasée avec verrouillage mécanique",
                        specs: [
                            'Capacité de charge jusqu’à 22 kW',
                            'Gestion de la charge intelligente',
                            'Installation rapide et sans câblage',
                            'Contrôle à distance via l’application mobile'
                        ],
                        technicalSheet: PdfHuaweiSmartCharger
                    }
                ]
            }
        }
    },
    heatpumps: {
        title: 'Pompes à chaleur',
        path: 'pompes-a-chaleur',
        description:
            "Profitez du confort et de l'efficacité énergétique avec des pompes à chaleur Daikin et Alpha Innotec.",
        icon: 'fire-flame-curved',
        image: AlphaInnotecLWD,
        bannerClass: 'pac',
        productsByBrand: {
            Daikin: {
                logo: LogoDaikin,
                image: DaikinAltherma,
                products: [
                    {
                        title: 'POMPE À CHALEUR 11KW HYDROSPLIT MONOPHASÉ MOYENNE TEMPÉRATURE R 32 ALTHERMA 3 ALTHERMA DAIKIN',
                        description:
                            'La pompe à chaleur Daikin Altherma 3e génération est la première pompe à chaleur Air/ Eau au R-32, conçue et fabriquée en Europe pour répondre aux besoins des utilisateurs européens. La technologie Bluevolution est le résultat de la conception par Daikin d’un nouveau compresseur à injection de gaz chaud haute performance développé pour le réfrigérant R-32. Ce nouveau produit convient aux projets de rénovation chauffage et aux constructions neuves (RT2012).',
                        specs: ['Garantie 3 ans pièces - 5 ans compresseur', 'Puissance nominale : 11 kW', 'COP : 4,38']
                    }
                ]
            },
            'Alpha Innotec': {
                logo: LogoAlphaInnotec,
                image: AlphaInnotecLWD,
                products: [
                    {
                        title: 'Série alira LWD - Deux pompes à chaleur air / eau pour une installation extérieure',
                        description:
                            "Le LWD 50A/SX-HMD est un produit innovant de la série Alira LWD, offrant aux installateurs et aux propriétaires de maison durabilité, efficacité, design moderne et facilité d'utilisation. La gamme réversible LWD RX assure le chauffage, le rafraîchissement, et la production d'eau chaude en un seul système. Ces pompes à chaleur sont flexibles, silencieuses, et s'intègrent facilement dans diverses installations. Le module hydraulique HMD2 permet de doubler la puissance de chauffage jusqu'à 20 kW. Ils sont également respectueux de'environnement avec leur réfrigérant naturel R 290, tout en offrant des avantages aux installateurs tels que des COP élevés, une installation rapide, et une construction monobloc.",
                        specs: [
                            "COP jusqu'à 4,79",
                            "Puissance de chauffage jusqu'à 7,4 kW",
                            'Installation rapide et facile',
                            'Contruction monobloc'
                        ]
                    }
                ]
            }
        }
    },
    isolation: {
        title: 'Isolation',
        path: 'isolation',
        description:
            "Améliorez l'isolation de votre maison avec nos panneaux isolants de qualité supérieure de la marque Swisspor et Fermacell.",
        icon: 'house',
        image: Isolation,
        bannerClass: 'isolation',
        productsByBrand: {
            Swisspor: {
                logo: LogoSwisspor,
                image: IsolationSwisspor,
                products: [
                    {
                        title: "Swisspor - L'excellence en isolation suisse",
                        description:
                            "Swisspor est reconnue pour ses produits d'isolation de fabrication suisse de premier ordre. Que ce soit avec leurs panneaux d'isolation en mousse rigide ou leurs isolants en fibres minérales, chaque produit est conçu pour assurer une efficacité énergétique optimale et un confort constant dans tous les types de bâtiments. Grâce à plus de 50 ans d'expérience, Swisspor propose des solutions complètes et fiables pour l'isolation, garantissant ainsi la durabilité et la performance de chaque projet.",
                        specs: [
                            'Les meilleures performances en matière de physique du bâtiment',
                            'Durabilité',
                            'Solutions complètes pour tous vos besoins en isolation',
                            'Isolants carrossables et praticables',
                            'Propriétés mécaniques élevées',
                            'Excellent rapport qualité-prix',
                            'Conductivité thermique exceptionnelle'
                        ]
                    }
                ]
            },
            Fermacell: {
                logo: LogoFermacell,
                image: IsolationFermacel,
                products: [
                    {
                        title: "Fermacell - L'excellence en panneaux de Fibres-Gypse",
                        description: '',
                        specs: [
                            'Installation simple',
                            'Stable et résistant',
                            'Résistant au feu',
                            "Amélioration de l'air ambiant"
                        ]
                    }
                ]
            }
        }
    },
    domesticHotWater: {
        title: 'Eau chaude sanitaire',
        path: 'eau-chaude-sanitaire',
        description:
            "Profitez d'une eau chaude abondante et économique avec nos chauffe-eau solaires de la marque Thermor et Vaillant.",
        icon: 'house-flood-water',
        image: WaterHeater,
        bannerClass: 'ecs',
        productsByBrand: {
            Vaillant: {
                logo: LogoVaillant,
                image: BallonVaillant,
                products: [
                    {
                        title: 'Chauffe-eau thermodynamique aroSTOR',
                        description: 'Le moyen écologique et efficace de préparer l’eau chaude sanitaire ',
                        specs: [
                            'Préparation d’eau chaude économique avec un réfrigérant naturel, le R290 neutre en CO2',
                            "Fonctionnement à l'énergie solaire possible toute l'année",
                            'Fonctionnement en mode pompe à chaleur jusqu’à -7°C',
                            'Fonctionnement ultra-silencieux',
                            'Capacité : 270 l (resp. 265 l avec registre de chauffage)'
                        ],
                        technicalSheet: PdfVaillantArostor
                    }
                ]
            }
        }
    }
};

interface Product {
    title: string;
    description: string;
    specs?: string[];
    technicalSheet?: string;
}

interface ProductsByBrand {
    [key: string]: {
        title?: string;
        logo: string;
        description?: string;
        image: string;
        products: Product[];
    };
}

interface ProductsByCategory {
    [key: string]: {
        title: string;
        path: string;
        description: string;
        showInHero?: boolean;
        productPage?: boolean;
        bannerClass?: string;
        icon: string;
        image: string;
        productsByBrand: ProductsByBrand;
    };
}
