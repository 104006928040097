const ErrorPage = () => {
    return (
        <>
            <div className='container-fluid page-header py-5 mb-5'>
                <div className='container py-5'>
                    <h1 className='display-3 text-white mb-3 animated slideInDown'>404 Error</h1>
                </div>
            </div>

            <div className='container-xxl py-5 wow fadeInUp' data-wow-delay='0.1s'>
                <div className='container text-center'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <i className='bi bi-exclamation-triangle display-1 text-primary'></i>
                            <h1 className='display-1'>404</h1>
                            <h1 className='mb-4'>Page Introuvable</h1>
                            <p className='mb-4'>
                                Nous sommes désolés, la page que vous avez recherchée n'existe pas sur notre site.
                            </p>
                            <a className='btn btn-primary rounded-pill py-3 px-5' href='index.html'>
                                Retournez à l'accueil
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ErrorPage;
