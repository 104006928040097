import Slider from 'react-slick';

import LogoAlphaInnotec from '../assets/images/logos/logo-alpha-innotec.png';
import LogoAPSystemes from '../assets/images/logos/logo-ap-systems.jpg';
import LogoDaikin from '../assets/images/logos/logo-daikin.png';
import LogoEmphase from '../assets/images/logos/logo-enphase.jpg';
import LogoESDEC from '../assets/images/logos/logo-esdec.jpg';
import LogoFermacell from '../assets/images/logos/logo-fermacell.png';
import LogoHuawei from '../assets/images/logos/logo-huawei.jpg';
import LogoK2Systemes from '../assets/images/logos/logo-k2-systems.jpg';
import LogoLongiSolor from '../assets/images/logos/logo-longi-solar.jpg';
import LogoMegaSol from '../assets/images/logos/logo-megasol.png';
import LogoSolarEdge from '../assets/images/logos/logo-solar-edge.jpg';
import LogoSwisspor from '../assets/images/logos/logo-swisspor.png';
import LogoThermor from '../assets/images/logos/logo-thermor.png';
import LogoThomson from '../assets/images/logos/logo-thomson.png';
import LogoTrinaSolar from '../assets/images/logos/logo-trina-solar.jpg';
import LogoVaillant from '../assets/images/logos/logo-vaillant.png';

const partners = [
    { image: LogoMegaSol },
    { image: LogoAPSystemes },
    { image: LogoEmphase },
    { image: LogoESDEC },
    { image: LogoDaikin },
    { image: LogoAlphaInnotec },
    { image: LogoHuawei },
    { image: LogoLongiSolor },
    { image: LogoK2Systemes },
    { image: LogoSolarEdge },
    { image: LogoTrinaSolar },
    { image: LogoThomson },
    { image: LogoFermacell },
    { image: LogoSwisspor },
    { image: LogoVaillant },
    { image: LogoThermor }
];

const Partners = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 750,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <Slider {...settings}>
            {partners.map((partner, index) => (
                <div key={index} className='d-flex justify-content-center'>
                    <img src={partner.image} alt='' />
                </div>
            ))}
        </Slider>
    );
};

export default Partners;
