import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const Topbar = () => (
    <Container fluid className='bg-dark p-0 text-white lh-lg'>
        <div className='row gx-0 d-none d-lg-flex'>
            <div className='col-lg-7 px-5 text-start'>
                <div className='h-100 d-inline-flex align-items-center me-4'>
                    <small className='fa fa-map-marker-alt text-primary me-2'></small>
                    <small>Votre expert en énergie présent dans toute la Suisse romande</small>
                    {/* <Button
                        variant='link'
                        className='text-white p-0'
                        href='https://maps.google.com/?q=Route+de+la+Jorette+1,+Torgon,+Suisse'
                        target='_blank'
                    >
                        <small>Route de la Jorette 1, 1899 Torgon, Canton du Valais</small>
                    </Button> */}
                </div>
                <div className='h-100 d-inline-flex align-items-center'>
                    <small className='far fa-clock text-primary me-2'></small>
                    <small>Lundi - Vendredi : 9h - 19h</small>
                </div>
            </div>
            <div className='col-lg-5 px-5 text-end'>
                <div className='h-100 d-inline-flex align-items-center me-4'>
                    <small className='fa fa-phone-alt text-primary me-2'></small>
                    <Button variant='link' className='text-white p-0' href='tel:+410275602112'>
                        <small>+41 027 560 21 12</small>
                    </Button>
                </div>
                <div className='h-100 d-inline-flex align-items-center mx-n2'>
                    <a
                        className='btn btn-square btn-link rounded-0 border-0 border-end border-secondary'
                        href='https://www.instagram.com/schaffnerenergy/'
                        target='_blank'
                    >
                        <i className='fab fa-instagram'></i>
                    </a>
                    <a
                        className='btn btn-square btn-link rounded-0 border-0 border-end border-secondary'
                        href='https://www.linkedin.com/company/schaffner-energy/'
                        target='_blank'
                    >
                        <i className='fab fa-linkedin-in'></i>
                    </a>
                    <a
                        className='btn btn-square btn-link rounded-0'
                        href='https://www.facebook.com/people/Schaffner-Energy/100095402058704/'
                        target='_blank'
                    >
                        <i className='fab fa-facebook-f'></i>
                    </a>
                </div>
            </div>
        </div>
    </Container>
);

export default Topbar;
