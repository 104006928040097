import { useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { Isotope, ProjectCard } from '../components';
import { Project, projectCategories, projects } from '../data/projects';

export const PhotoModal = ({
    project,
    show,
    onHide
}: {
    project: Project;
    show: ModalProps['show'];
    onHide: ModalProps['onHide'];
}) => {
    return (
        <Modal show={show} onHide={onHide} size='lg' aria-labelledby='photo-modal' centered>
            <Modal.Header closeButton>
                <Modal.Title id='photo-modal'>
                    {project.title} - {project.subtitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='text-center'>
                    <img className='w-100' src={project.img} alt='' />
                </div>
            </Modal.Body>
        </Modal>
    );
};

const Projects = () => {
    const [show, setShow] = useState(false);
    const [currentProject, setCurrentProject] = useState<Project | null>(null);

    const handleShow = (project: Project) => {
        setCurrentProject(project);
        setShow(true);
    };

    return (
        <>
            <div className='container-fluid page-header-installations py-5 mb-5'>
                <div className='container py-5'>
                    <h1 className='display-3 text-white mb-3 animated slideInDown'>Installations réalisées</h1>
                </div>
            </div>

            <div className='container-xxl py-5 mb-5'>
                <div className='container'>
                    <Isotope container='portfolio-container' itemSelector='portfolio-item'>
                        <div className='row mt-n2 wow fadeInUp' data-wow-delay='0.3s'>
                            <div className='col-12 text-center'>
                                <ul className='list-inline mb-5' id='portfolio-flters'>
                                    <Isotope.Filter category='*' title='Voir tout' />
                                    {Object.keys(projectCategories).map((key, index) => (
                                        <Isotope.Filter
                                            key={index}
                                            category={projectCategories[key].name}
                                            title={projectCategories[key].title}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className='row g-4 portfolio-container wow fadeInUp' data-wow-delay='0.5s'>
                            {projects.map((project: Project, index) => {
                                return (
                                    <ProjectCard
                                        key={index}
                                        img={project.img}
                                        category={project.category}
                                        subtitle={project.subtitle}
                                        openModal={() => handleShow(project)}
                                    />
                                );
                            })}
                        </div>
                    </Isotope>
                </div>
            </div>

            {currentProject && <PhotoModal show={show} project={currentProject} onHide={() => setShow(false)} />}
        </>
    );
};

export default Projects;
