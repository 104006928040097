interface ProjectCardProps {
    img: string;
    category: { name: string; title: string };
    subtitle: string;
    openModal: () => void;
    column?: string;
    animation?: string;
    animationDuration?: string;
}

const ProjectCard = ({
    img,
    category,
    subtitle,
    openModal,
    column = 'col-lg-4 col-md-6',
    animation = '',
    animationDuration = '1s'
}: ProjectCardProps) => {
    return (
        <div
            className={`${column} portfolio-item ${category.name} ${animation ? 'wow animate__' + animation : ''}`}
            data-wow-duration={animationDuration}
        >
            <div className='portfolio-img rounded overflow-hidden'>
                <img src={img} alt={`${category.title} schaffner energy group`} />
                <div role='button' className='portfolio-btn' onClick={openModal}>
                    <button className='btn btn-lg-square btn-outline-light rounded-circle mx-1'>
                        <i className='fa fa-eye'></i>
                    </button>
                </div>
            </div>
            <div className='pt-3'>
                <p className='text-primary mb-0'>{category.title}</p>
                <hr className='text-primary w-25 my-2' />
                <h5 className='lh-base'>{subtitle}</h5>
            </div>
        </div>
    );
};

export default ProjectCard;
