import Hangar1 from './../assets/images/installations/hangars-agricoles/hangar-1.jpg';
import Hangar2 from './../assets/images/installations/hangars-agricoles/hangar-2.jpg';
import Hangar3 from './../assets/images/installations/hangars-agricoles/hangar-3.jpg';
import Hangar4 from './../assets/images/installations/hangars-agricoles/hangar-4.jpg';
import Hangar5 from './../assets/images/installations/hangars-agricoles/hangar-5.jpg';
import Home1 from './../assets/images/installations/maisons-habitations/maison-1.jpg';
import Home2 from './../assets/images/installations/maisons-habitations/maison-2.jpg';
import Home3 from './../assets/images/installations/maisons-habitations/maison-3.jpg';
import Home4 from './../assets/images/installations/maisons-habitations/maison-4.jpg';

export const projectCategories: ProjectCategory = {
    maison: {
        name: 'maison',
        title: 'Maisons / Chalets'
    },
    agricole: {
        name: 'agricole',
        title: 'Hangars agricoles'
    }
};

export const projects: Project[] = [
    {
        img: Home1,
        category: projectCategories.maison,
        title: 'Maison / Chalet',
        subtitle: 'Panneaux solaires'
    },
    {
        img: Hangar1,
        category: projectCategories.agricole,
        title: 'Hangar agricole',
        subtitle: 'Panneaux solaires'
    },
    {
        img: Home2,
        category: projectCategories.maison,
        title: 'Maison / Chalet',
        subtitle: 'Panneaux solaires'
    },
    {
        img: Hangar2,
        category: projectCategories.agricole,
        title: 'Hangar agricole',
        subtitle: 'Panneaux solaires'
    },
    {
        img: Home3,
        category: projectCategories.maison,
        title: 'Maison / Chalet',
        subtitle: 'Panneaux solaires'
    },
    {
        img: Hangar3,
        category: projectCategories.agricole,
        title: 'Hangar agricole',
        subtitle: 'Panneaux solaires'
    },
    {
        img: Home4,
        category: projectCategories.maison,
        title: 'Maison / Chalet',
        subtitle: 'Panneaux solaires'
    },
    {
        img: Hangar4,
        category: projectCategories.agricole,
        title: 'Hangar agricole',
        subtitle: 'Panneaux solaires'
    },
    {
        img: Hangar5,
        category: projectCategories.agricole,
        title: 'Hangar agricole',
        subtitle: 'Panneaux solaires'
    }
];

export interface ProjectCategory {
    [key: string]: { name: string; title: string };
}

export interface Project {
    img: string;
    category: { name: string; title: string };
    title: string;
    subtitle: string;
}
