import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';

export { Step1, Step2, Step3, Step4, Step5, Step6, Step7 };

export const steps = [
    {
        label: 'Step 1',
        component: Step1,
        nextButton: {
            label: 'Valider mon adresse',
            validationFields: ['completeAddress']
        },
        showMapOnMobile: true
    },
    {
        label: 'Step 2',
        component: Step2,
        nextButton: {
            label: 'Suivant',
            validationFields: ['buildingType']
        }
    },
    {
        label: 'Step 3',
        component: Step3,
        nextButton: {
            label: 'Suivant',
            validationFields: ['heatingType']
        }
    },
    {
        label: 'Step 4',
        component: Step4,
        nextButton: {
            label: 'Suivant',
            validationFields: ['electricityBill']
        }
    },
    // {
    //     label: 'Step 5',
    //     component: Step5,
    //     nextButton: {
    //         label: 'Valider mes informations',
    //         validationFields: ['firstName', 'lastName', 'phone']
    //     }
    // },
    {
        label: 'Step 6',
        component: Step6,
        nextButton: {
            label: 'Terminer ma simulation',
            validationFields: ['numPanels']
        },
        showMapOnMobile: true,
        onEnter: 'activateSimulator',
        onExit: 'deactivateSimulator'
    },
    {
        label: 'Step 7',
        component: Step7
    }
];
