import { Form } from 'react-bootstrap';
import { CardOption } from '../../components';
import { StepProps } from './types';

const options = [
    {
        title: '< 2000 CHF',
        value: '<2000'
    },
    {
        title: '2000 - 3000 CHF',
        value: '2000-3000'
    },
    {
        title: '> 3000 CHF',
        value: '>3000'
    },
    {
        title: 'Je ne sais pas',
        value: 'unknown'
    }
];

const Step4 = ({ formState, handleValueChange }: StepProps) => (
    <Form className='d-flex flex-column justify-content-center align-content-center mx-5 h-100'>
        <Form.Group controlId='electricityBill'>
            <Form.Label>
                <strong>Montant de la facture d'électricité annuelle :</strong>
            </Form.Label>

            <div className='d-flex flex-wrap gap-4'>
                {options.map((option, index) => (
                    <CardOption
                        key={index}
                        title={option.title}
                        selected={formState.electricityBill === option.value}
                        onClick={() => handleValueChange('electricityBill', option.value)}
                    />
                ))}
            </div>
        </Form.Group>
    </Form>
);

export default Step4;
