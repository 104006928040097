import { default as IsotopeLayout } from 'isotope-layout';
import React, { useEffect, useRef, useState } from 'react';

const Filter = ({ category, title, ...props }) => {
    return (
        <li
            className={`mx-2 ${props.currentFilterKey === category ? 'active' : ''}`}
            data-filter={category}
            onClick={() => props.handleFilterKeyChange(category)}
        >
            {title}
        </li>
    );
};

const Isotope = ({ container, itemSelector, children }) => {
    // init one ref to store the future isotope object
    const isotope = useRef();
    // store the filter keyword in a state
    const [filterKey, setFilterKey] = useState('*');

    // initialize an Isotope object with configs
    useEffect(() => {
        isotope.current = new IsotopeLayout(`.${container}`, {
            itemSelector: `.${itemSelector}`,
            layoutMode: 'fitRows'
        });
        // cleanup
        return () => isotope.current.destroy();
    }, []);

    // handling filter key change
    useEffect(() => {
        filterKey === '*'
            ? isotope.current.arrange({ filter: `*` })
            : isotope.current.arrange({ filter: `.${filterKey}` });
    }, [filterKey]);

    const recursiveCloneChildren = children => {
        return React.Children.map(children, child => {
            var childProps = {};
            if (child.type === Filter) {
                childProps['currentFilterKey'] = filterKey;
                childProps['handleFilterKeyChange'] = () => setFilterKey(child.props.category);
            }
            if (child.props.children) {
                childProps.children = recursiveCloneChildren(child.props.children);
            }
            return React.cloneElement(child, childProps);
        });
    };

    return recursiveCloneChildren(children);
};

Isotope.Filter = Filter;

export default Isotope;
