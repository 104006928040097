import { Outlet } from 'react-router-dom';
import { CookieConsent, Footer, Navbar, ScrollToTop, Spinner, Topbar, Wow } from './components';

const App = () => (
    <>
        <Wow />
        <ScrollToTop />
        <Spinner />
        <CookieConsent />
        <Topbar />
        <Navbar />
        <Outlet />
        <Footer />
    </>
);

export default App;
