import { Form } from 'react-bootstrap';
import { CardOption } from '../../components';
import { StepProps } from './types';

const options = [
    {
        title: 'Fioul',
        icon: 'droplet',
        value: 'Fioul'
    },
    {
        title: 'Gaz',
        icon: 'fire-flame-curved',
        value: 'Gaz'
    },
    {
        title: 'Électrique',
        icon: 'bolt',
        value: 'Électrique'
    },
    {
        title: 'Bois',
        icon: 'tree',
        value: 'Bois'
    },
    {
        title: 'Autre',
        icon: 'question-circle',
        value: 'Autre'
    }
];

const Step3 = ({ formState, handleValueChange }: StepProps) => (
    <Form className='d-flex flex-column justify-content-center align-content-center mx-5 h-100'>
        <Form.Group controlId='heatingType'>
            <Form.Label>
                <strong>Indiquez le type de chauffage :</strong>
            </Form.Label>
            <div className='d-flex flex-wrap gap-4'>
                {options.map((option, index) => (
                    <CardOption
                        key={index}
                        title={option.title}
                        icon={option.icon}
                        selected={formState.heatingType === option.value}
                        onClick={() => handleValueChange('heatingType', option.value)}
                    />
                ))}
            </div>
        </Form.Group>
    </Form>
);

export default Step3;
