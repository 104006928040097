import { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo-schaffner-energy-group-sa.png';
import Stepper from './Stepper';

import './../assets/simulator.css';

const SimulatorNavbar = ({ steps, currentStep }: { steps: number; currentStep: number }) => {
    const [stickyClass, setStickyClass] = useState('');

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);
        return () => window.removeEventListener('scroll', stickNavbar);
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            windowHeight > 150 ? setStickyClass('shadow-sm active') : setStickyClass('');
        }
    };
    return (
        <Navbar expand='lg' bg='white' className={`sticky-top p-0 shadow-sm ${stickyClass}`}>
            <Navbar.Brand as={NavLink} to='/' className='d-flex align-items-center border-end px-4 px-lg-5'>
                <img src={logo} height='70px' width='218px' alt='logo schaffner energy group sa' />
            </Navbar.Brand>
            <div className='d-none d-lg-block'>
                <Stepper steps={steps} currentStep={currentStep + 1} size={25} lineWidth={100} />
            </div>
        </Navbar>
    );
};

export default SimulatorNavbar;
