import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WOW from 'wowjs';

const Wow = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        new WOW.WOW({ live: false }).init();
    }, [pathname]);

    return null;
};

export default Wow;
