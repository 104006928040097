interface StepperContainerProps {
    children: React.ReactNode;
    py: number;
    px: number;
    width: number;
    height: number;
}

const StepperContainer = ({ children, py, px, width, height }: StepperContainerProps) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fff',
            width: `${width}px`,
            height: `${height}px`,
            padding: `${py}px ${px}px `
        }}
    >
        {children}
    </div>
);

const RoundImage = ({ src, size, color }: { src: string; size: number; color: string }) => (
    <img
        src={src}
        alt=''
        style={{
            width: `${size}px`,
            height: `${size}px`,
            backgroundColor: `${color}`,
            border: `2px solid ${color}`,
            borderRadius: `${size}px`,
            padding: '2px'
        }}
    />
);

const RoundIcon = ({ size, color, active }: { size: number; color: string; active: boolean }) => (
    <div
        style={{
            ...{
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: '25px'
            },
            ...(active
                ? {
                      animationDelay: '1s',
                      boxShadow: `0px 0px 0px 6px ${color} inset`,
                      border: `2px solid ${color}`,
                      transition: '0.5s ease-in-out 0.3s'
                  }
                : {
                      border: '2px solid #ccc',
                      backgroundColor: '#fff'
                  })
        }}
    />
);

const GreyLine = ({ width, children }: { width: number; children: React.ReactNode }) => (
    <div
        style={{
            backgroundColor: '#ccc',
            width: `${width}px`,
            height: '2px'
        }}
    >
        {children}
    </div>
);

const GreenLine = ({ width, color }: { width: number; color: string }) => (
    <div
        style={{
            backgroundColor: color,
            width: `${width}px`,
            height: '2px',
            animation: 'lineFillAnim 0.5s linear'
        }}
    />
);

const StepRound = ({ active, done, size, color }: { active: boolean; done: boolean; size: number; color: string }) => {
    return done ? (
        <RoundImage
            size={size}
            color='#d92d27'
            src={`data:image/svg+xml;base64,
PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUxMS45OTkgNTExLjk5OSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTExLjk5OSA1MTEuOTk5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiPjxnPjxnPgoJPGc+CgkJPHBhdGggZD0iTTUwNi4yMzEsNzUuNTA4Yy03LjY4OS03LjY5LTIwLjE1OC03LjY5LTI3Ljg0OSwwbC0zMTkuMjEsMzE5LjIxMUwzMy42MTcsMjY5LjE2M2MtNy42ODktNy42OTEtMjAuMTU4LTcuNjkxLTI3Ljg0OSwwICAgIGMtNy42OSw3LjY5LTcuNjksMjAuMTU4LDAsMjcuODQ5bDEzOS40ODEsMTM5LjQ4MWM3LjY4Nyw3LjY4NywyMC4xNiw3LjY4OSwyNy44NDksMGwzMzMuMTMzLTMzMy4xMzYgICAgQzUxMy45MjEsOTUuNjY2LDUxMy45MjEsODMuMTk4LDUwNi4yMzEsNzUuNTA4eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4=`}
        />
    ) : (
        <RoundIcon size={size} color={color} active={active} />
    );
};

const StepLine = ({ active, width, color }: { active: boolean; width: number; color: string }) => (
    <GreyLine width={width}>{active && <GreenLine width={width} color={color} />}</GreyLine>
);

interface StepperProps {
    steps?: number;
    currentStep?: number;
    px?: number;
    py?: number;
    size?: number;
    lineWidth?: number;
    color?: string;
}

const Stepper = ({
    steps = 3,
    currentStep = 1,
    px = 16,
    py = 12,
    size = 20,
    lineWidth = 30,
    color = '#d92d27'
}: StepperProps) => {
    currentStep = currentStep - 1;

    const width = steps * (size + 4) + lineWidth * (steps - 1) + 2 * px;
    const height = size + 2 * py;
    const setpsArray = Array(steps).fill('1');

    return (
        <StepperContainer py={py} px={px} width={width} height={height}>
            {setpsArray.map((_, index) => (
                <div className='d-flex align-items-center' key={index}>
                    <StepRound
                        done={index <= currentStep - 1 || currentStep === steps - 1}
                        active={index === currentStep}
                        size={size}
                        color={color}
                    />
                    {index !== steps - 1 && (
                        <StepLine active={index <= currentStep - 1} width={lineWidth} color={color} />
                    )}
                </div>
            ))}
        </StepperContainer>
    );
};

export default Stepper;
