import emailjs from '@emailjs/browser';
import { useState } from 'react';

import ContactImage from './../assets/images/slider/schaffner-bureau.jpg';

const Contact = () => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [formValues, setFormValues] = useState({
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        date: new Date().toLocaleDateString('fr')
    });

    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        emailjs
            .send('service_qsmoznc', 'template_163bj1h', formValues, 'By0mP_22032uCbEPG')
            .then(_ => {
                setSuccess(true);
            })
            .catch(error => {
                console.error(error);
                setError(true);
            });
    };

    const handleValueChange = (key: string, value: string) => {
        setFormValues({
            ...formValues,
            [key]: value
        });
    };

    return (
        <>
            <div className='container-fluid page-header-contact py-5'>
                <div className='container py-5'>
                    <h1 className='display-3 text-white mb-3 animated slideInDown'>Contactez-nous</h1>
                </div>
            </div>

            <div className='container-fluid bg-light overflow-hidden px-lg-0'>
                <div className='container contact px-lg-0'>
                    <div className='row g-0 mx-lg-0'>
                        <div className='col-lg-6 py-5 p-md-5 wow fadeIn' data-wow-delay='0.5s'>
                            <div className='p-md-5'>
                                <h6 className='text-primary'>Contactez-nous</h6>
                                <h1 className='mb-4'>Utilisez le formulaire ci-dessous pour nous envoyez un message</h1>
                                <p className='mb-4'>
                                    Vous pouvez nous laisser un message avec vos coordonnées. Nous vous rappelerons
                                    rapidement.
                                </p>
                                <form onSubmit={sendEmail}>
                                    <div className='row g-3'>
                                        <div className='col-md-6'>
                                            <div className='form-floating'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='lastname'
                                                    placeholder='Nom'
                                                    value={formValues.lastname}
                                                    onChange={e => handleValueChange('lastname', e.target.value)}
                                                />
                                                <label htmlFor='lastname'>Nom</label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-floating'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='firstname'
                                                    placeholder='Prénom'
                                                    value={formValues.firstname}
                                                    onChange={e => handleValueChange('firstname', e.target.value)}
                                                />
                                                <label htmlFor='firstname'>Prénom</label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-floating'>
                                                <input
                                                    type='email'
                                                    className='form-control'
                                                    id='email'
                                                    placeholder='Email'
                                                    value={formValues.email}
                                                    onChange={e => handleValueChange('email', e.target.value)}
                                                />
                                                <label htmlFor='email'>Email</label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-floating'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='email'
                                                    placeholder='Natel / Téléphone'
                                                    value={formValues.phone}
                                                    onChange={e => handleValueChange('phone', e.target.value)}
                                                />
                                                <label htmlFor='email'>Natel / Téléphone</label>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='form-floating'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='subject'
                                                    placeholder='Sujet'
                                                    value={formValues.subject}
                                                    onChange={e => handleValueChange('subject', e.target.value)}
                                                />
                                                <label htmlFor='subject'>Sujet</label>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='form-floating'>
                                                <textarea
                                                    className='form-control'
                                                    placeholder='Message'
                                                    id='message'
                                                    value={formValues.message}
                                                    onChange={e => handleValueChange('message', e.target.value)}
                                                    style={{ height: '100px' }}
                                                ></textarea>
                                                <label htmlFor='message'>Message</label>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <button className='btn btn-primary rounded-pill py-3 px-5' type='submit'>
                                                Envoyez le message
                                            </button>
                                        </div>
                                        {success && (
                                            <div className='col-12'>
                                                <div className='alert alert-success mt-3' role='alert'>
                                                    Votre message a été envoyé avec succès !
                                                </div>
                                            </div>
                                        )}
                                        {error && (
                                            <div className='col-12'>
                                                <div className='alert alert-danger mt-3' role='alert'>
                                                    Une erreur est survenue lors de l'envoi du message. Veuillez
                                                    réessayer.
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-lg-6 pe-lg-0' style={{ minHeight: '400px' }}>
                            <div className='position-relative h-100'>
                                <img src={ContactImage} alt='Switzerland' className='object-fit-cover w-100 h-100' />
                                {/* <iframe
                                    className='position-absolute w-100 h-100'
                                    title='Schaffner Energy Group SA'
                                    style={{ objectFit: 'cover' }}
                                    src='https://maps.google.com/maps?q=Route%20de%20Gouillon%2014&ie=UTF8&t=p&z=19&iwloc=B&output=embed'
                                    aria-hidden='false'
                                ></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
