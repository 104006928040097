import rectangleGrid from '@turf/rectangle-grid';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { useEffect, useState } from 'react';

const Step6 = ({
    map,
    draw,
    formState: { selection },
    mapDrawEnabled,
    handleValueChange,
    clearMap: globalClearMap,
    addLayer
}) => {
    const [mapDraw, setMapDraw] = useState(null);

    // EVENT LISTENERS
    useEffect(() => {
        if (!map.current) return;

        map.current.on('draw.create', updateArea);
        map.current.on('draw.update', updateArea);
        map.current.on('draw.delete', deleteArea);

        return () => {
            map.current.off('draw.create', updateArea);
            map.current.off('draw.update', updateArea);
            map.current.off('draw.delete', deleteArea);
        };
    }, [draw, selection]);

    // RE-DRAW PANEL GRID
    useEffect(() => {
        if (mapDraw) return; // prevent infinite loop

        if (selection && mapDrawEnabled) {
            const ids = draw.current.add(selection);
            draw.current.changeMode('simple_select', { featureIds: ids });
            clearMap();
            updateArea();
            setMapDraw(true);
        } else if (mapDrawEnabled) {
            setMapDraw(true);
            draw.current.changeMode('draw_polygon');
        }
    });

    const updateArea = () => {
        clearMap();

        const data = draw.current.getAll();
        const lengths = [];

        if (data.features.length > 0) {
            var previousLength = 0;
            var longestLineBearing;

            data.features[0].geometry.coordinates[0].forEach((coord, index) => {
                if (index !== 0) {
                    // LENGTH
                    const line = turf.lineString([data.features[0].geometry.coordinates[0][index - 1], coord]);
                    const length = turf.length(line, { units: 'meters' });
                    const rounded_length = Math.round(length * 100) / 100;
                    lengths.push(rounded_length);

                    // MIDPOINT
                    const point1 = data.features[0].geometry.coordinates[0][index - 1];
                    const point2 = coord;
                    const midpoint = turf.midpoint(point1, point2);

                    if (rounded_length > previousLength) {
                        longestLineBearing = turf.bearing(point1, point2);
                        previousLength = rounded_length;
                    }

                    // ADD POPUP
                    createPopup(midpoint.geometry.coordinates, `${rounded_length} m`);
                }
            });

            // CALCULATE BEARING TO MATCH LONGEST LINE
            const newBearing = longestLineBearing < 0 ? 90 - Math.abs(longestLineBearing) : longestLineBearing - 90;
            showSolarPanels(newBearing);
        }
    };

    const showSolarPanels = newBearing => {
        const selectionPolygon = draw.current.getAll();

        const solarProduction = 450; // kWh / year
        const spaceBetweenPanels = 0.05;

        // OFFSET POLYGON WITH BUFFER
        const buffered = turf.buffer(selectionPolygon, -0.05, { units: 'meters' });

        // DRAW GRID WITH MASK
        const bbox = turf.bbox(buffered);
        const bboxPolygon = turf.bboxPolygon(bbox);

        const solarPanelWidth = 1 + spaceBetweenPanels;
        const solarPanelHeight = 1.7 + spaceBetweenPanels;
        const verticalPanelGrid = getPanelGrid(
            buffered,
            bbox,
            bboxPolygon,
            solarPanelWidth,
            solarPanelHeight,
            spaceBetweenPanels,
            newBearing
        );
        const horizontalPanelGrid = getPanelGrid(
            buffered,
            bbox,
            bboxPolygon,
            solarPanelHeight,
            solarPanelWidth,
            spaceBetweenPanels,
            newBearing
        );

        var gridMask;
        if (verticalPanelGrid.features.length > horizontalPanelGrid.features.length) {
            gridMask = verticalPanelGrid;
            handleValueChange('panelOrientation', 'vertical');
        } else {
            gridMask = horizontalPanelGrid;
            handleValueChange('panelOrientation', 'horizontal');
        }

        addLayer(gridMask, 'fill', { color: 'black', prefix: 'panel' });

        map.current.fitBounds(bbox, {
            bearing: newBearing,
            center: turf.center(bboxPolygon).geometry.coordinates,
            duration: 1000
        });

        handleValueChange('selection', selectionPolygon);
        handleValueChange('numPanels', gridMask.features.length);
        handleValueChange('solarProduction', gridMask.features.length * solarProduction);
    };

    const getPanelGrid = (
        buffered,
        bbox,
        bboxPolygon,
        solarPanelWidth,
        solarPanelHeight,
        spaceBetweenPanels,
        newBearing
    ) => {
        const grid = rectangleGrid(bbox, solarPanelWidth, solarPanelHeight, {
            units: 'meters'
        });

        // REMOVE GRID OUTSIDE OF BUFFER AND ROTATE EACH FEATURE TO MATCH LONGEST LINE AND ADD BUFFER
        return turf.featureCollection(
            grid.features
                .map(feature => turf.buffer(feature, -(spaceBetweenPanels / 2), { units: 'meters' })) // space between panels
                .map(feature =>
                    turf.transformRotate(feature, newBearing, {
                        pivot: turf.centerOfMass(bboxPolygon).geometry.coordinates
                    })
                )
                .filter(feature => turf.booleanContains(buffered.features[0], feature))
        );
    };

    const deleteArea = () => {
        clearMap();

        // CHANGE MODE TO DRAW_POLYGON
        draw.current.changeMode('draw_polygon');
    };

    const createPopup = (coordinates, text) => {
        new mapboxgl.Popup({
            closeOnClick: false,
            closeButton: false,
            className: 'custom-popup'
        })
            .setLngLat(coordinates)
            .setHTML(text)
            .addTo(map.current);
    };

    const clearMap = () => {
        globalClearMap();
        handleValueChange('numPanels', null);
    };

    return (
        <div className='d-flex flex-column justify-content-center align-content-center h-100 mx-5'>
            <h2>Sélectionnez votre toit pour les panneaux solaires</h2>
            <p>
                Maintenant, passons à l'étape suivante ! Vous allez choisir l'emplacement de vos panneaux solaires en
                sélectionnant votre toit sur la carte. Il vous suffit de cliquer sur le toit de votre maison sur la
                carte pour indiquer où vous souhaitez les installer. Si votre maison n'est pas visible, déplacez-vous
                sur la carte en cliquant et en faisant glisser pour trouver votre emplacement exact.
            </p>
        </div>
    );
};

export default Step6;
