import { Card } from 'react-bootstrap';

import './../assets/simulator.css';

interface CardOptionProps {
    title: string;
    icon?: string;
    selected: boolean;
    onClick: () => void;
}

const CardOption = ({ title, icon, selected, onClick }: CardOptionProps) => {
    return (
        <Card
            className={`card-option ${selected ? 'selected' : ''} flex-fill`}
            style={{ width: '45%' }}
            onClick={onClick}
        >
            <Card.Body className='d-flex flex-column justify-content-center align-items-center'>
                {icon && <i className={`fa fa-${icon} fa-2x`}></i>}
                <Card.Title className='mt-2 font-weight-bold'>{title}</Card.Title>
            </Card.Body>
        </Card>
    );
};

export default CardOption;
